import React from "react";
import "./_weare.scss";

const WhoWeAre = () => {
  const menu = [
    {
      heading: "APPLICATION HELP",
      title: "Personalized Job application assistance",
      img: "./images/services1.png",
      desc: "With AsaanApply you get to have your own personalized job application assistant, helping you with all of your job applications. Either you are a student graduating soon and looking for internships or re.cent graduate looking forward to get into job market and start shining career. AsaanApply makes this easy for you by aiming to help you apply for jobs as well as getting documentations done saving you from investing your precious time and physical exhaustion. All you have to do is ask AsaanApply, prepare for test and receive the interview call",
    },
    {
      heading: "HASSALE FREE PROCESS",
      img: "./images/services2.png",
      title: "Dedicated team for each step",
      desc: "AsaanApply saves you from repeatedly filling forms, making copies of your educational and experience credentials, making pictures, submitting bank chalans and sending application package through courier every time you apply for a new job. AsaanApply team does all these tedious processes for you every time your preferred job vacancy is advertised. With AsaanApply you get an access to one click application platform. All you have to do is create account and fill all information fields required, select your preferred jobs that you want to apply for and wait. AsaanApply team will ask for your consent every time relevant opportunity is advertised, give your content and let us do magic.",
    },
    {
      heading: "ONE CLICK PLATFORM",
      img: "./images/services3.png",
      title: "Everything at one place",
      desc: "You get to have job application management where you can have all information available related to specific job in one place. Expected dates of coming tests, test preparation material, milestones to keep on tract in test preparation, your progress, reminders and test results. In your path towards a good job AsaanApply got you.",
    },
  ];
  const isOdd = (number) => number % 2 !== 0;
  return (
    <>
      <section className="who_we_are">
        <div className="container">
          <div className="col-md-12 text-center mx-auto mb-5">
            <h1 className="">
              What <span className="text-primary">AsaanApply</span> clients get  
            </h1>
          </div>

          <div className="col-md-12 mx-auto who_card_box">
            {menu.map((item, index) => {
              if (isOdd(index + 1)) {
                return (
                  <div className="row align-items-center mt-5" key={index}>
                    <div className="col-md-12 col-lg-7">
                      <span className="d-inline-block text-primary mb-3">
                        {item.heading}
                      </span>
                      <h4 className="mb-2 sub-heading-who"> {item.title} </h4>
                      <p> {item.desc} </p>
                    </div>
                    <div className="col-md-12 col-lg-5 text-end">
                      <img className="i" src={item.img} alt={item.title} />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="row align-items-center mt-5" key={index}>
                    <div className="col-md-12 col-lg-5 text-start">
                      <img className="i" src={item.img} alt={item.title} />
                    </div>
                    <div className="col-md-12 col-lg-7">
                      <span className="d-inline-block text-primary mb-3">
                        {item.heading}
                      </span>
                      <h4 className="mb-2 sub-heading-who"> {item.title} </h4>
                      <p> {item.desc} </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
