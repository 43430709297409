// import { React, useState, useEffect } from "react";
// import "./auth.scss";
// import { Link } from "react-router-dom";
// import { IoEye } from "react-icons/io5";
// import { IoEyeOff } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
// import { clearMessage, loginUser } from "../../Services/Reducers/UserSlice";
// import { useNavigate } from "react-router-dom";
// const LoginPage = () => {
//   const navigate = useNavigate();
//   const [data, setData] = useState({
//     email: "",
//     password: "",
//   });
//   const [showPassword, setshowPassword] = useState(false);

//   const passwordToggle = () => {
//     setshowPassword(!showPassword);
//   };



//   const dispatch = useDispatch();
//   const { loading, successMessage } = useSelector((state) => state.user);


//   const handleChange = (e) => {
//     setData({ ...data, [e.target.name]: e.target.value })

//   }

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(loginUser(data))
//     .then((result)=>{
//       if(result.payload && result.payload.token){
//         navigate('/stepper');
//       }
//     })
//     .catch((error)=>{
//       console.log("Login Error:", error);
//     })
//   }
//   useEffect(() => {
//     clearMessage();
//   },[])
//   return (
//     <>
//       <section className="auth-section container">
//         <div className="row col-12">
//           <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
//             <div className="d-flex flex-column justify-content-center align-items-center p-4">
//               <div>
//                 <h3>Welcome back!</h3>
//                 <p>Use you credentials to Login to your account</p>
//                 <p>
//                   If you did not register yet, Sign Up to create your account
//                 </p>
//               </div>
//               <div>
//                 <img
//                   src="./images/services5.png"
//                   className="img-size img-fluid"
//                   alt="icon"
//                 />
//               </div>
//             </div>
//           </div>
//           {/* form head start*/}
//           <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
//             <div className="card  text-center ">
//               <img src="./images/logo.jpg" alt=" logo" className="img-fluid" />
//               <h3 className="para_welcome mb-4 mt-4">Welcome back!</h3>
//               <p className="text-muted mb-2" style={{ fontSize: "12px" }}>
//                 Enter your Email and Password to log in
//               </p>
//               {/* form head end */}
//               {/* form main start*/}
//               <form className="log-register mt-3" onSubmit={handleSubmit}>
//                 <div className="f-group text-start">
//                   <label className="f-label mb-2">Email*</label>
//                   <input
//                     className="form-control mb-3 text-secondary"
//                     id="input-1"
//                     type="email"
//                     name="email"
//                     placeholder="Example@***.com"
//                     onChange={handleChange}
//                   ></input>
//                 </div>
//                 <div className="f-group text-start mt-3">
//                   <label className="f-label mb-2">Password*</label>
//                   <div className="position-relative">
//                     <input className="form-control text-secondary pr-5"  // Add padding to the right to make space for the icon
//                       id="input-2"
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       placeholder="********"
//                       onChange={handleChange}
//                     />
//                     <div
//                       onClick={passwordToggle}
//                       className="position-absolute"
//                       style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
//                     >
//                       {showPassword ? <IoEyeOff /> : <IoEye />}
//                     </div>
//                   </div>
//                 </div>
//                 {/* form main end  */}

//                 <button
//                   className=" hover-up mb-1 mt-4 w-100"
//                   type="submit"
//                   name="login"
//                 >
//                   Login
//                 </button>

//                 <div className="pb-3 py-1 d-flex align-items-center mt-3">
//                   <p className="mb-0 text-muted">
//                     <small>Haven't Account ?</small>{" "}
//                   </p>
//                   <Link className="ms-1" to={"/register"}>
//                     <small>Register</small>
//                   </Link>
//                 </div>
//               </form>
//             </div>
//           </div>
//           {/* sign in buttons end  */}
//         </div>
//       </section>
//     </>
//   );
// };

// export default LoginPage;


import React, { useState, useEffect } from "react";
import "./auth.scss";
import { Link } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, loginUser } from "../../Services/Reducers/UserSlice";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const passwordToggle = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const { loading, successMessage, loginError } = useSelector((state) => state.user);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(data))
      .then((result) => {
        if (result.payload && result.payload.token) {
          navigate('/stepper');
        }
      })
      .catch((error) => {
        console.log("Login Error:", error);
      });
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  return (
    <>
      <section className="auth-section container">
        <div className="row col-12">
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center p-4">
              <div>
                <h3>Welcome back!</h3>
                <p>Use your credentials to Login to your account</p>
                <p>If you did not register yet, Sign Up to create your account</p>
              </div>
              <div>
                <img src="./images/services5.png" className="img-size img-fluid" alt="icon" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
            <div className="card text-center">
              <img src="./images/logo.jpg" alt="logo" className="img-fluid" />
              <h3 className="para_welcome mb-4 mt-4">Welcome back!</h3>
              <p className="text-muted mb-2" style={{ fontSize: "12px" }}>
                Enter your Email and Password to log in
              </p>
              <form className="log-register mt-3" onSubmit={handleSubmit}>
                <div className="f-group text-start">
                  <label className="f-label mb-2">Email*</label>
                  <input
                    className="form-control mb-3 text-secondary"
                    id="input-1"
                    type="email"
                    name="email"
                    placeholder="Example@***.com"
                    onChange={handleChange}
                  />
                </div>
                <div className="f-group text-start mt-3">
                  <label className="f-label mb-2">Password*</label>
                  <div className="position-relative">
                    <input
                      className="form-control text-secondary pr-5"
                      id="input-2"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="********"
                      onChange={handleChange}
                    />
                    <div
                      onClick={passwordToggle}
                      className="position-absolute"
                      style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    >
                      {showPassword ? <IoEyeOff /> : <IoEye />}
                    </div>
                  </div>
                </div>
                <button
                  className="hover-up mb-1 mt-4 w-100"
                  type="submit"
                  name="login"
                  disabled={loading}
                >
                  Login
                </button>

                {/* Display error message */}
                {loginError && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {loginError}
                  </div>
                )}

                <div className="pb-3 py-1 d-flex align-items-center mt-3">
                  <p className="mb-0 text-muted">
                    <small>Haven't Account?</small>{" "}
                  </p>
                  <Link className="ms-1" to={"/register"}>
                    <small>Register</small>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;

