import React from "react";
import "./pricing.scss";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const index = () => {
  return (
    <>
      <section className="pricing_plan">
        <div className="container">
          <div className="col-md-12 mx-auto">
            <h2 className="text-center mb-4">
              Flexible and Honest Pricing Plans
            </h2>
            <p className="text-center">
              AsaanApply offers different pricing plans according to your need
              and affordability
            </p>
            <div className="container d-flex flex-row justify-content-center align-items-center  mt-1 mb-4">
              <ul className="nav nav-pills border p-2 rounded tabs " id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active "
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Monthly plans
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Annual plans
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Individual plans
                  </button>
                </li>
              </ul>
            </div>

            <Row>
              <Col cols="3" size="lg" className="mt-2">
                <Card className="pricingCard  main__pricing__card">
                  <h5 className="title-text">Government job application </h5>
                  <div>
                    <div className="d-flex flex-direction-row justify-content-between mt-2">
                      <h6>200PKR</h6>
                      <p>Per application</p>
                    </div>
                    <ul className="pricing-list ">
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>{" "}
                        <span className="mx-2">Access to Bambee HR</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">4 Core HR Policies</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Custom HR Policy</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Job Descriptions</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">Chat and email support</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3 single_price">
                    <h5>$00.00</h5>
                  </div>
                </Card>
              </Col>
              <Col cols="3" size="lg" className="mt-2">
                <Card className="pricingCard  main__pricing__card">
                  <h5 className="title-text">Private job application </h5>

                  <div>
                    <div className="d-flex flex-direction-row justify-content-between mt-2">
                      <h6>200PKR</h6>
                      <p>Per application</p>
                    </div>
                    <ul className="pricing-list ">
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>{" "}
                        <span className="mx-2">Access to Bambee HR</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">4 Core HR Policies</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Custom HR Policy</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Job Descriptions</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">Chat and email support</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3 single_price">
                    <h5>$00.00</h5>
                  </div>
                </Card>
              </Col>
              <Col cols="3" size="lg" className="mt-2">
                <Card className="pricingCard  main__pricing__card">
                  <h5 className="title-text">Internship application </h5>
                  <div>
                    <div className="d-flex flex-direction-row justify-content-between mt-2">
                      <h6>200PKR</h6>
                      <p>Per application</p>
                    </div>
                    <ul className="pricing-list ">
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>{" "}
                        <span className="mx-2">Access to Bambee HR</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">4 Core HR Policies</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Custom HR Policy</span>
                      </li>
                      <li className="pb-3">
                        {" "}
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">2 Job Descriptions</span>
                      </li>
                      <li className="pb-3">
                        <span className="fa-price-icon">
                          <FontAwesomeIcon
                            style={{ fill: "#9398d0" }}
                            icon={faCheck}
                          />
                        </span>
                        <span className="mx-2">Chat and email support</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3 single_price">
                    <h5>$00.00</h5>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default index;
