import React from 'react'
import './pendingApproval.css'
const PendingApproval = () => {
    return (
        <div className='container'>
            <h1>Pending Approval</h1>
            <p>Your respone has been submitted, please submit your payment at <b>01234567890</b> or if you have already submitted then contact on whatsapp at the mentioned contact number. Thanks</p>
        </div>
    )
}

export default PendingApproval
