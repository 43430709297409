import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import "./_index.scss";

const Header = () => {
  const Links = ["Home", "About", "Job News", "Services", "Career", "Contact"];
  const [isToggle, setIsToggle] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      setScrolling(isScrolling);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    // backgroundColor: scrolling ? "#40adba" : "transparent",
    backgroundColor: true ? "#40adba" : "transparent",
    height: "70px",
    // Add any other styles you want for your header
  };
  return (
    <Navbar className={`header`} style={headerStyle} >
      <div className="container">
        <nav className="navbar">
          <Link to={"/"} className="navbar-brand">
            <img src="./images/logo-png.png" alt="white logo" />
          </Link>

          <button
            className="btn border-0 text-white humberger_icons"
            onClick={() => setIsToggle(true)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div
            className={[
              isToggle ? "active desktop_navigation" : "desktop_navigation",
            ]}
          >
            <button
              className="btn border-0 text-white close_icons"
              onClick={() => setIsToggle(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>

            <Link to={"/"} className="mobile_logo navbar-brand">
              <img src="./images/logo-png.png" alt="white logo" />
            </Link>
            <div className="navbar-nav ms-xl-5">
              {Links.map((link, index) => {
                switch (link) {
                  case "Home":
                    return (
                      <Link
                        to="/"
                        className="nav-link"
                        key={index}
                        onClick={() => setIsToggle(false)}
                      >
                        {link}
                      </Link>
                    );
                  case "Job News":
                    return (
                      <Link
                        to="/jobs"
                        className="nav-link"
                        key={index}
                        onClick={() => setIsToggle(false)}
                      >
                        {link}
                      </Link>
                    );
                  default:
                    return (
                      <Link
                        to={link}
                        className="nav-link"
                        key={index}
                        onClick={() => setIsToggle(false)}
                      >
                        {link}
                      </Link>
                    );
                }
              })}
            </div>

            <div className="auth-buttons">
              <Link
                to={"/login"}
                className="btn btn-outline-light px-5 auth-button"
                onClick={() => setIsToggle(false)}
              >
                Login
              </Link>
              <Link
                to={"/register"}
                className="btn btn-primary px-5 auth-button"
                onClick={() => setIsToggle(false)}
              >
                Register
              </Link>
            </div>
          </div>

          <button className="btn text-white border-0 outline-0"></button>
        </nav>
      </div>
    </Navbar>
  );
};

export default Header;
