import React from "react";
import "./_testimonials.scss";

const Testimonials = (props) => {
  return (
    <>
      <div className="section-test pb-5">
        <div className="container container__testimonials">
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-sm-12">
              <p className="para__1 mt-5">CLIENTS ABOUT US</p>
              <h2 className="heading__1 ">Testimonials</h2>
              <p className="test__para_1 ">
                See what our existing clients have to say about us
              </p>
            </div>
            <div className="col-lg-6 col-sm-12 mt-5">
              <div className="row">
                <div className="col-sm-6 d-flex justify-content-center align-items-center">
                  <figure className="snip1157">
                    <blockquote>
                      Calvin: You know sometimes when I'm talking, my words
                      can't keep up with my thoughts... I wonder why we think
                      faster than we speak. Hobbes: Probably so we can think
                      twice.
                      <div className="arrow"></div>
                    </blockquote>
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg"
                      alt="sq-sample3"
                    />
                    <div className="author">
                      <h5 className="test__name">
                        Pelican Steve{" "}<br />
                        <span className="test__span"> Project Owner</span>
                      </h5>
                    </div>
                  </figure>
                </div>
                <div className="col-sm-6 d-flex justify-content-center align-items-center">
                  <figure className="snip1157">
                    <blockquote>
                      Calvin: You know sometimes when I'm talking, my words
                      can't keep up with my thoughts... I wonder why we think
                      faster than we speak. Hobbes: Probably so we can think
                      twice.
                      <div className="arrow"></div>
                    </blockquote>
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg"
                      alt="sq-sample3"
                    />
                    <div className="author">
                      <h5 className="test__name">
                        Pelican Steve{" "} <br />
                        <span className="test__span"> Product manager</span>
                      </h5>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
