import "./scss/_variables.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/index";
import Home from "./pages/Home";
import Footer from "./components/Footer/footer";
import Stepper from "./components/Stepper/Stepper"    
import SignUpPage from "./components/auth/SignUpPage";
import LoginPage from "./components/auth/LoginPage";
import Ourservices from "./pages/ourServices/ourServices";
import AboutUs from "./pages/AboutUs/AboutUs";
import Career from "./pages/Career/Career";
import JobNews from "./pages/JobNewsCard/JobNewsCard";
import Contact from "./pages/Contact/Contact";
import UserProfile from "./pages/Profile/UserProfile";
import ScrollToTop from "./components/utils/ScrollToTop";
import PendingApproval from "./pages/pendingApproval/PendingApproval";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/register" element={<SignUpPage />}></Route>
          <Route path="/services" element={<Ourservices />}></Route>
          <Route path="/career" element={<Career />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>
          <Route path="/jobs" element={<JobNews />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/user-profile" element={<UserProfile />}></Route>
          <Route path="/stepper" element={<Stepper />}></Route>
          <Route path="/pendingApproval" element={<PendingApproval />}></Route>
        </Routes>
        <Footer />

        {/* <Stepper /> */}

      </div>
    </Router>
  );
}

export default App;
