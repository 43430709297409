import React, { useState } from 'react'
import './UserProfile.css'
const UserProfile = () => {

    // Personal Information Usestates start Here
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [gender, setGender] = useState("")
    const [dob, setDOB] = useState("")
    const [pob, setPOB] = useState("")
    const [maritalStatus, setMaritalStatus] = useState("")
    const [sector, setSector] = useState("")
    const [cnic, setCNIC] = useState("")
    const [religion, setReligion] = useState("")
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")
    const [bloodGroup, setBloodGroup] = useState("")
    const [passportNo, setPassportNo] = useState("")
    const [typeOfPassport, setTypeOfPassport] = useState("")
    const [passportDateOfIssuance, setPassportDateOfIssuance] = useState("")
    const [passportPlaceOfIssuance, setPassportPlaceOfIssuance] = useState("")
    const [primaryEmailAddress, setPrimaryEmailAddress] = useState("")
    const [confirmEmailAddress, setConfirmEmailAddress] = useState("")
    const [otherEmailAddress, setOtherEmailAddress] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [confirmMobileNo, setConfirmMobileNo] = useState("")
    const [otherContactNo, setOtherContactNo] = useState("")
    const [nationality, setNationality] = useState("")
    const [dualNational, setDualNational] = useState("")
    const [province, setProvince] = useState("")
    const [city, setCity] = useState("")
    const [zone, setZone] = useState("")
    const [permanentAddress, setPermanentAddress] = useState("")
    const [postalAddress, setPostalAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    // Personal Information Usestate End Here

    // Guardian Information Start Here

    const [fatherName, setFatherName] = useState("")
    const [fatherState, setFatherState] = useState("")
    const [fatherCNIC, setFatherCNIC] = useState("")
    const [fatherOccupation, setFatherOccupation] = useState("")
    const [fatherEmployer, setFatherEmployer] = useState("")
    const [fatherContact, setFatherContact] = useState("")
    const [fatherDesignation, setFatherDesignation] = useState("")
    const [fatherIncome, setFatherIncome] = useState("")
    const [fatherEducation, setFatherEducation] = useState("")
    const [motherName, setMotherName] = useState("")
    const [motherCNIC, setMotherCNIC] = useState("")
    const [motherOccupation, setMotherOccupation] = useState("")
    const [motherDesignation, setMotherDesignation] = useState("")
    const [motherIncome, setMotherIncome] = useState("")
    const [motherEducation, setMotherEducation] = useState("")
    const [noOfSiblings, setNoOfSiblings] = useState("")
    const [nameAndRelationOfSiblings, setNameAndRelationOfSiblings] = useState("")
    // Guardian Information End Here

    // Personal Information Handle
    const personalInformationHandle = (event) => {
        event.preventDefault()

        const formData = new FormData()

        formData.append('firstName', firstName);
        formData.append('middleName', middleName);
        formData.append('lastName', lastName);
        formData.append('gender', gender);
        formData.append('dob', dob);
        formData.append('pob', pob)
        formData.append('maritalStatus', maritalStatus)
        formData.append('sector', sector)
        formData.append('cnic', cnic)
        formData.append('religion', religion)
        formData.append('height', height)
        formData.append('weight', weight)
        formData.append('bloodGroup', bloodGroup)
        formData.append('passportNo', passportNo)
        formData.append('typeOfPassport', typeOfPassport)
        formData.append('passportDateOfIssuance', passportDateOfIssuance)
        formData.append('passportPlaceOfIssuance', passportPlaceOfIssuance)
        formData.append('primaryEmailAddress', primaryEmailAddress)
        formData.append('confirmEmailAddress', confirmEmailAddress)
        formData.append('otherEmailAddress', otherEmailAddress)
        formData.append('mobileNo', mobileNo)
        formData.append('confirmMobileNo', confirmMobileNo)
        formData.append('otherContactNo', otherContactNo)
        formData.append('nationality', nationality)
        formData.append('dualNational', dualNational)
        formData.append('province', province)
        formData.append('city', city)
        formData.append('zone', zone)
        formData.append('permanentAddress', permanentAddress)
        formData.append('postalAddress', postalAddress)
        formData.append('postalCode', postalCode)
        console.log(dualNational)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
    }

    const guardianInformationHandle = (event) => {
        event.preventDefault()
        console.log(fatherName)
        const formData = new FormData()

        formData.append('fatherName', fatherName);
        formData.append('fatherState', fatherState);
        formData.append('fatherCNIC', fatherCNIC);
        formData.append('fatherOccupation', fatherOccupation);
        formData.append('fatherEmployer', fatherEmployer);
        formData.append('fatherContact', fatherContact);
        formData.append('fatherDesignation', fatherDesignation);
        formData.append('fatherIncome', fatherIncome);
        formData.append('fatherEducation', fatherEducation);
        formData.append('motherName', motherName);
        formData.append('motherCNIC', motherCNIC);
        formData.append('motherOccupation', motherOccupation);
        formData.append('motherDesignation', motherDesignation);
        formData.append('motherIncome', motherIncome);
        formData.append('motherEducation', motherEducation);
        formData.append('noOfSiblings', noOfSiblings);
        formData.append('nameAndRelationOfSiblings', nameAndRelationOfSiblings);

        console.log(formData)
        for (const [key, value] of formData.entries()) {
            console.log(key, value);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "radio") {
            // Handle radio button changes
            if (checked) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        } else {
            // Handle other input changes
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const [formData, setFormData] = useState({
        currentOccupation: "",
        currentDesignation: "",
        desiredTestCity1: "",
        desiredTestCity2: "",
        HafizQuran: "",
        ageRelations: "",
        physicalDisability: "",
        convicted: "",
        dismissed: "",
        govenmentEmploy: "",
        disabillity: "",
        abraodDetails: "",
        sportActivities: "",
        hobbies: "",

    })
    const handleOccupationInformation = (e) => {
        e.preventDefault();
        console.log(formData)

    }

    // Education Handles 
    const handleEducationInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "radio") {
            // Handle radio button changes
            if (checked) {
                setEducationFormData({
                    ...educationFormData,
                    [name]: value,
                });
            }
        } else {
            // Handle other input changes
            setEducationFormData({
                ...educationFormData,
                [name]: value
            });
        }

    };

    const [educationFormData, setEducationFormData] = useState({
        qualification: "",
        degreeName: "",
        durationFromMonth: "",
        durationFromYear: "",
        cpga: "",
        cgpaObtained: "",
        degreeStatus: "",
        board: "",
        country: "",
        durationToMonth: "",
        durationToYear: "",
        subject: "",
    })
    const handleEducationInformation = (e) => {
        e.preventDefault();
        console.log(educationFormData)

    }
    return (
        <>
            <div className="container mt-5">
                <div className="row pt-5">
                    <div className="col-2">
                        <div className="nav flex-column nav-pills me-3 backcolor" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button className="nav-link active outline" id="v-pills-Personal-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Personal" type="button" role="tab" aria-controls="v-pills-Personal" aria-selected="true">Personal Information</button>
                            <button className="nav-link outline " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Guardian Information</button>
                            <button className="nav-link outline " id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Occupation</button>
                            <button className="nav-link outline " id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Education Background</button>
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="tab-content p-0" id="v-pills-tabContent">
                            <div className="tab-pane fade show active width-size" id="v-pills-Personal" role="tabpanel" aria-labelledby="v-pills-Personal-tab" tabIndex="0"><div className='container'>

                                {/* personal information */}
                                <form action="#" className="container bg-custom" onSubmit={personalInformationHandle}>
                                    <div className="pb-1 pt-1 line-bottom">
                                        <span className="form-title fw-bold">Please fill in your basic personal information:</span>
                                    </div>

                                    <div className="form-row pt-4">
                                        <span className="label">First Name:</span>
                                        <input className='input-field' type="text" aria-label="First name"
                                            value={firstName}
                                            onChange={(e) => { setFirstName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <span className="label">*Middle Name:</span>
                                        <input className='input-field' type="text" aria-label="Middle name"
                                            value={middleName}
                                            onChange={(e) => { setMiddleName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <span className="label">Last Name:</span>
                                        <input className='input-field' type="text" aria-label="Last name"
                                            value={lastName}
                                            onChange={(e) => { setLastName(e.target.value) }}

                                        />
                                    </div>
                                    <div className="form-row">
                                        <span className="label">* Gender:</span>
                                        <select className="input-field" required id="inputGroupSelect02"
                                            value={gender}
                                            onChange={(e) => { setGender(e.target.value) }}
                                        >
                                            <option selected disabled >Choose...</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Date of Birth:</span>
                                        <input type="date" required className="input-field" name="date" id="date"
                                            value={dob}
                                            onChange={(e) => { setDOB(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <span className="label">Place of Birth:</span>
                                        <input type="date" required className="input-field" name="date" id="date"
                                            value={pob}
                                            onChange={(e) => { setPOB(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <span className='span'>Marital Status: </span>
                                        <select id="marital" name="marital" className="input-field"
                                            value={maritalStatus}
                                            onChange={(e) => { setMaritalStatus(e.target.value) }}
                                        >
                                            <option selected disabled>Choose...</option>
                                            <option value="Afghanistan">Married</option>
                                            <option value="Åland Islands">UnMarried</option>
                                        </select>
                                    </div>

                                    <div className="form-row">
                                        <span className="label">Sector:</span>
                                        <input type="text" className="input-field"
                                            value={sector}
                                            onChange={(e) => { setSector(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <span className="label">CNIC:</span>
                                        <input type="number" className="input-field" name="CNIC" id="CNIC"
                                            value={cnic}
                                            onChange={(e) => { setCNIC(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className='span'>Religion: </span>
                                        <select className="" id="religion" name="religion"
                                            value={religion}
                                            onChange={(e) => { setReligion(e.target.value) }}
                                        >
                                            <option selected disabled>Choose...</option>
                                            <option value="African Traditional &amp; Diasporic">African Traditional &amp; Diasporic</option>
                                            <option value="Agnostic">Agnostic</option>
                                            <option value="Atheist">Atheist</option>
                                            <option value="Baha'i">Baha'i</option>
                                            <option value="Buddhism">Buddhism</option>
                                            <option value="Cao Dai">Cao Dai</option>
                                            <option value="Chinese traditional religion">Chinese traditional religion</option>
                                            <option value="Christianity">Christianity</option>
                                            <option value="Hinduism">Hinduism</option>
                                            <option value="Islam">Islam</option>
                                            <option value="Jainism">Jainism</option>
                                            <option value="Juche">Juche</option>
                                            <option value="Judaism">Judaism</option>
                                            <option value="Neo-Paganism">Neo-Paganism</option>
                                            <option value="Nonreligious">Nonreligious</option>
                                            <option value="Rastafarianism">Rastafarianism</option>
                                            <option value="Secular">Secular</option>
                                            <option value="Shinto">Shinto</option>
                                            <option value="Sikhism">Sikhism</option>
                                            <option value="Spiritism">Spiritism</option>
                                            <option value="Tenrikyo">Tenrikyo</option>
                                            <option value="Unitarian-Universalism">Unitarian-Universalism</option>
                                            <option value="Zoroastrianism">Zoroastrianism</option>
                                            <option value="primal-indigenous">primal-indigenous</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div className="form-row">
                                        <span className="label">* Height:</span>
                                        <input type="text" required className="input-field " name="height" id="height"
                                            value={height}
                                            onChange={(e) => { setHeight(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Weight:</span>
                                        <input type="text" required className="input-field" name="Weight" id="Weight"
                                            value={weight}
                                            onChange={(e) => { setWeight(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Blood group:</span>
                                        <input type="text" required className="input-field " name="bloodg" id="bloodg"
                                            value={bloodGroup}
                                            onChange={(e) => { setBloodGroup(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Passport No.:</span>
                                        <input type="text" required className="input-field"
                                            value={passportNo}
                                            onChange={(e) => { setPassportNo(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className='label'>* Type of Passport:</span>
                                        <input type="text" required className="input-field "
                                            value={typeOfPassport}
                                            onChange={(e) => { setTypeOfPassport(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Passport Date of issuance:</span>
                                        <input type="date" required className="input-field"
                                            value={passportDateOfIssuance}
                                            onChange={(e) => { setPassportDateOfIssuance(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Passport Place of Issuance:</span>
                                        <input type="text" required className="input-field "
                                            value={passportPlaceOfIssuance}
                                            onChange={(e) => { setPassportPlaceOfIssuance(e.target.value) }} />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Primary Email Address:</span>
                                        <input type="email" className="input-field "
                                            value={primaryEmailAddress}
                                            onChange={(e) => { setPrimaryEmailAddress(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Confirm Email Address:</span>
                                        <input type="email" className="input-field"
                                            value={confirmEmailAddress}
                                            onChange={(e) => { setConfirmEmailAddress(e.target.value) }} />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">* Other Email Address:</span>
                                        <input type="email" required className="input-field"
                                            value={otherEmailAddress}
                                            onChange={(e) => { setOtherEmailAddress(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Mobile Number:</span>
                                        <input type="tel" className="input-field "
                                            value={mobileNo}
                                            onChange={(e) => { setMobileNo(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Confirm Mobile Number:</span>
                                        <input type="tel" className="input-field"
                                            value={confirmMobileNo}
                                            onChange={(e) => { setConfirmMobileNo(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <span className="label">* Other Contact Number:</span>
                                        <input type="tel" required className="input-field "
                                            value={otherContactNo}
                                            onChange={(e) => { setOtherContactNo(e.target.value) }}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Nationality:</span>
                                        <select id="citizen" name="citizen" className="input-field"
                                            value={nationality}
                                            onChange={(e) => { setNationality(e.target.value) }}
                                        >
                                            <option selected disabled>Choose...</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-bissau">Guinea-bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-leste">Timor-leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>


                                    <div className="form-row radio-buttons">
                                        <label className="label">Are you a dual/foreign national (Yes/No):</label>
                                        <input type="radio" name="flexRadioDefault" id="flexRadioDefault1" className='input-field'
                                            onChange={(e) => { setDualNational("Yes") }}
                                        />
                                        <label htmlFor="flexRadioDefault1">Yes</label>&nbsp;&nbsp;
                                        <input type="radio" name="flexRadioDefault" id="flexRadioDefault2" className='input-field'
                                            onChange={(e) => { setDualNational("No") }}
                                        />
                                        <label className="mr-5" htmlFor="flexRadioDefault2">No</label>
                                    </div>




                                    <div className="form-row">
                                        <span className="label">Province:</span>
                                        <input type="text" className="input-field "
                                            value={province}
                                            onChange={(e) => { setProvince(e.target.value) }} />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">City:</span>
                                        <input type="text" className="input-field"
                                            value={city}
                                            onChange={(e) => { setCity(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <span className="label">Domicile:</span>
                                        <input type="file" className="input-field file  file " id="upload" />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Zone:</span>
                                        <input type="text" className="input-field"
                                            value={zone}
                                            onChange={(e) => { setZone(e.target.value) }} />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Permanent Address:</span>
                                        <input type="text" className="input-field"
                                            value={permanentAddress}
                                            onChange={(e) => { setPermanentAddress(e.target.value) }} />
                                    </div>


                                    <div className="form-row">
                                        <span className="label">Postal Address:</span>
                                        <input type="text" className="input-field"
                                            value={postalAddress}
                                            onChange={(e) => { setPostalAddress(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <span className="label">Postal Code:</span>
                                        <input type="number" className="input-field "
                                            value={postalCode}
                                            onChange={(e) => { setPostalCode(e.target.value) }}
                                        />
                                    </div>


                                    <div className="button-center">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>

                            </div>
                            </div>



                            {/* Gaurdian information */}
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">

                                <form action="" className='container  bg-custom' onSubmit={guardianInformationHandle}>
                                    <div className='line-bottom pb-1 pt-1'>
                                        <span className='form-title fw-bold'>Please fill Guardian information:<br /></span>
                                    </div>

                                    <div className="form-row mt-4">
                                        <span className='span'>Father Name: </span>
                                        <input className='input-field' type="text"
                                            value={fatherName}
                                            onChange={(e) => { setFatherName(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <span className='span'>Alive/departed: </span>
                                        <input className='input-field' type="text"
                                            value={fatherState}
                                            onChange={(e) => { setFatherState(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>Father CNIC: </span>
                                        <input type="number" className='input-field'
                                            value={fatherCNIC}
                                            onChange={(e) => { setFatherCNIC(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className=' span'>Father occupation: </span>
                                        <input type="text" className='input-field'
                                            value={fatherOccupation}
                                            onChange={(e) => { setFatherOccupation(e.target.value) }} /><br />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* Father employer: </span>
                                        <input type="text" required className='input-field'
                                            value={fatherEmployer}
                                            onChange={(e) => { setFatherEmployer(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className=' span'>* Father contact: </span>
                                        <input type="tel" required className='input-field'
                                            value={fatherContact}
                                            onChange={(e) => { setFatherContact(e.target.value) }} /><br />

                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* Father designation: </span>
                                        <input type="text" className='input-field'
                                            value={fatherDesignation}
                                            onChange={(e) => { setFatherDesignation(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* Father income: </span>
                                        <input type="text" required className='input-field'
                                            value={fatherIncome}
                                            onChange={(e) => { setFatherIncome(e.target.value) }} /><br />
                                    </div>

                                    <div className="form-row">

                                        <span className='span' >* Father education: </span>
                                        <input type="text" required className='input-field'
                                            value={fatherEducation}
                                            onChange={(e) => { setFatherEducation(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className=' span'>* Mother name: </span>
                                        <input type="text" required className='input-field'
                                            value={motherName}
                                            onChange={(e) => { setMotherName(e.target.value) }} /><br />
                                    </div>
                                    <div className="form-row">

                                        <span className=' span'>* Mother CNIC: </span>
                                        <input type="number" required className='input-field'
                                            value={motherCNIC}
                                            onChange={(e) => { setMotherCNIC(e.target.value) }} /><br />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* Mother occupation: </span>
                                        <input type="text" required className='input-field'
                                            value={motherOccupation}
                                            onChange={(e) => { setMotherOccupation(e.target.value) }} />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* Mother designation: </span>
                                        <input type="text" required className='input-field'
                                            value={motherDesignation}
                                            onChange={(e) => { setMotherDesignation(e.target.value) }} /><br />
                                    </div>
                                    <div className="form-row">

                                        <span className='span'>* Mother income: </span>
                                        <input type="text" required className='input-field'
                                            value={motherIncome}
                                            onChange={(e) => { setMotherIncome(e.target.value) }} />

                                    </div>

                                    <div className="form-row">
                                        <span className=' span'>* Mother education: </span>
                                        <input type="text" required className='input-field'
                                            value={motherEducation}
                                            onChange={(e) => { setMotherEducation(e.target.value) }} /><br />
                                    </div>

                                    <div className="form-row">

                                        <span className='span'>* No of Siblings: </span>
                                        <input type="number" required className='input-field'
                                            value={noOfSiblings}
                                            onChange={(e) => { setNoOfSiblings(e.target.value) }} />
                                    </div>
                                    <div className="form-row">
                                        <span className='span'>* Names and relation of siblings: </span>
                                        <input type="text" required className='input-field'
                                            value={nameAndRelationOfSiblings}
                                            onChange={(e) => { setNameAndRelationOfSiblings(e.target.value) }} /><br />

                                    </div>
                                    <div className="button-center">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </form>

                            </div>


                            {/* {handleOccupationInformation} */}

                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                <div className='tab-content col-12 p-0 container'>
                                    <form action="" className='container  bg-custom' onSubmit={handleOccupationInformation}>
                                        <div className='line-bottom'>
                                            <span className='form-title pb-1 pt-1'>Please fill Occupation information:<br /></span>
                                        </div>

                                        <div className="form-row mt-4">

                                            <span className='label' >Current occupation: </span>
                                            <input type="text" className='input-field'
                                                name='currentOccupation'
                                                value={formData.currentOccupation}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="form-row">

                                            <span className='label'>Current designation: </span>
                                            <input type="text" className='input-field'
                                                name='currentDesignation'
                                                value={formData.currentDesignation}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="form-row">

                                            <span className='label'>Desired test city1: </span>
                                            <input type="text" className='input-field'
                                                name='desiredTestCity1'
                                                value={formData.desiredTestCity1}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-row">

                                            <span className='label'>Desired test city2: </span>
                                            <input type="text" className='input-field'
                                                name='desiredTestCity2'
                                                value={formData.desiredTestCity2}
                                                onChange={handleInputChange}
                                            />
                                        </div>




                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Are you a hafiz e Quran(Yes/No)</span>
                                            <input type="radio" name="HafizQuran" id="flexRadioDefault5" className='input-field'

                                                value="Yes"
                                                onChange={handleInputChange}

                                            />&nbsp;
                                            <label for="flexRadioDefault5">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="HafizQuran" id="flexRadioDefault6" value="No" onChange={handleInputChange} />&nbsp;
                                            <label for="flexRadioDefault6">
                                                No
                                            </label>
                                        </div>


                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Do you claim age relaxation(Yes/No)</span><br />
                                            <input type="radio" name="ageRelaxations" id="flexRadioDefault7" className='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault7">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="ageRelaxations" id="flexRadioDefault8"
                                                value="No"
                                                onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault8">
                                                No
                                            </label>
                                        </div>


                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Do you claim physical disability(Yes/No)</span><br />
                                            <input type="radio" name="physicalDisability" id="flexRadioDefault9" className='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault9">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="physicalDisability" id="flexRadioDefault10"
                                                value="No" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault10">
                                                No
                                            </label>

                                        </div>

                                        <div className="form-row radio-buttons">

                                            <span className='label1' >Are you convicted(Yes/No)</span>
                                            <input type="radio" name="convicted" id="flexRadioDefault13" className='input-field' style={{ marginLeft: '69px' }}
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault13">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="convicted" id="flexRadioDefault14"
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault14">
                                                No
                                            </label>
                                        </div>
                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Have you been dismissed/removed form service under federal or provincial government?(Yes/No)</span>
                                            <input type="radio" name="dismissed" id="flexRadioDefault16" className='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault16">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="dismissed" id="flexRadioDefault17"
                                                value="No" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault17">
                                                No
                                            </label>
                                        </div>
                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Are you a government employee, if yes date of entry into first government service(Yes/No)</span>
                                            <input type="radio" name="GovenmentEmploye" id="flexRadioDefault3" className='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label className="" for="flexRadioDefault3">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="GovenmentEmploye" id="flexRadioDefault4"
                                                value="No" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault4">
                                                No
                                            </label>
                                        </div>

                                        <div className="form-row radio-buttons">

                                            <span className='label1'>Nature of disability/do you need a person for test(Yes/No)</span>
                                            <input type="radio" name="disability" id="flexRadioDefault11" className='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault11">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="disability" id="flexRadioDefault12"
                                                value="No" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault12">
                                                No
                                            </label>
                                        </div>
                                        <div className="form-row">

                                            <span className='label1'>Write details if you have been abroad</span>
                                            <textarea className="mx-3 p-2" id="abroadDetails" name='abraodDetails' rows="3"
                                                value={formData.abraodDetails} onChange={handleInputChange}
                                            ></textarea>
                                        </div>

                                        <div className="form-row radio-buttons">
                                            <span className='label1'>Co-curricular activities/Sports activities</span>
                                            <input className="form-check-input" type="radio" name="sportActivities" id="flexRadioDefault16" class='input-field'
                                                value="Yes" onChange={handleInputChange}
                                            />&nbsp;
                                            <label for="flexRadioDefault16">
                                                Yes
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input className="form-check-input" type="radio" name="sportActivities" id="flexRadioDefault17" class='input-field'

                                                value="No" onChange={handleInputChange} />&nbsp;
                                            <label for="flexRadioDefault17">
                                                No
                                            </label>
                                        </div>

                                        <div className="form-row">
                                            <span className='label'>Hobbies</span>
                                            <input type="text" className='input-field' name='hobbies' value={formData.hobbies} onChange={handleInputChange} />
                                        </div>
                                        <div className="button-center">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                                <div className='container'>
                                    <form action="" className='container  bg-custom' onSubmit={handleEducationInformation}>
                                        <div className='line-bottom'>
                                            <span className='form-title pb-1 pt-1 fw-bold'>Please fill Education background:<br /></span>
                                        </div>

                                        <div className="form-row mt-4">

                                            <span className='label'>Qualification: </span>
                                            <select name="qualification" id="qualification"
                                                value={educationFormData.qualification}
                                                onChange={handleEducationInputChange}>
                                                <option value="Under Matric">Under Matric</option>
                                                <option value="Matric/ OLevels / Equivalent">Matric / OLevels / Equivalent</option>
                                                <option value="Intermediate / A Level / Equivalent">Intermediate / A Level / Equivalent</option>
                                                <option value="BA / BSc / BE / BS / BBA / MBBS / BDS / Equivalent">BA / BSc / BE / BS / BBA / MBBS / BDS / Equivalent</option>
                                                <option value="MSc / MS / MBA / MCPS / Equivalent">MSc / MS / MBA / MCPS / Equivalent</option>
                                                <option value="MPhil">MPhil</option>
                                                <option value="PhD / FCPS / FRCS / MRCP / Equivalent">PhD / FCPS / FRCS / MRCP / Equivalent</option>
                                                <option value="Postdoctoral">Postdoctoral</option>
                                            </select>
                                        </div>

                                        <div className="form-row">

                                            <span className='label'>Degree Name: </span>
                                            <input type="text" className='input-field' name='degreeName'
                                                value={educationFormData.degreeName}
                                                onChange={handleEducationInputChange} />
                                        </div>

                                        <div >

                                            <span className='label'>Duration From: </span>
                                            <select name="durationFromMonth" id="custom-select" className='margin-left'
                                                value={educationFormData.durationFromMonth}
                                                onChange={handleEducationInputChange}>
                                                <option selected value=''>Month</option>
                                                <option value='1'>Janaury</option>
                                                <option value='2'>February</option>
                                                <option value='3'>March</option>
                                                <option value='4'>April</option>
                                                <option value='5'>May</option>
                                                <option value='6'>June</option>
                                                <option value='7'>July</option>
                                                <option value='8'>August</option>
                                                <option value='9'>September</option>
                                                <option value='10'>October</option>
                                                <option value='11'>November</option>
                                                <option value='12'>December</option>
                                            </select>
                                            <select id="custom-select" name="durationFromYear"
                                                value={educationFormData.durationFromYear}
                                                onChange={handleEducationInputChange}>
                                                <option selected>Year</option>
                                                <option value="1940">1940</option>
                                                <option value="1941">1941</option>
                                                <option value="1942">1942</option>
                                                <option value="1943">1943</option>
                                                <option value="1944">1944</option>
                                                <option value="1945">1945</option>
                                                <option value="1946">1946</option>
                                                <option value="1947">1947</option>
                                                <option value="1948">1948</option>
                                                <option value="1949">1949</option>
                                                <option value="1950">1950</option>
                                                <option value="1951">1951</option>
                                                <option value="1952">1952</option>
                                                <option value="1953">1953</option>
                                                <option value="1954">1954</option>
                                                <option value="1955">1955</option>
                                                <option value="1956">1956</option>
                                                <option value="1957">1957</option>
                                                <option value="1958">1958</option>
                                                <option value="1959">1959</option>
                                                <option value="1960">1960</option>
                                                <option value="1961">1961</option>
                                                <option value="1962">1962</option>
                                                <option value="1963">1963</option>
                                                <option value="1964">1964</option>
                                                <option value="1965">1965</option>
                                                <option value="1966">1966</option>
                                                <option value="1967">1967</option>
                                                <option value="1968">1968</option>
                                                <option value="1969">1969</option>
                                                <option value="1970">1970</option>
                                                <option value="1971">1971</option>
                                                <option value="1972">1972</option>
                                                <option value="1973">1973</option>
                                                <option value="1974">1974</option>
                                                <option value="1975">1975</option>
                                                <option value="1976">1976</option>
                                                <option value="1977">1977</option>
                                                <option value="1978">1978</option>
                                                <option value="1979">1979</option>
                                                <option value="1980">1980</option>
                                                <option value="1981">1981</option>
                                                <option value="1982">1982</option>
                                                <option value="1983">1983</option>
                                                <option value="1984">1984</option>
                                                <option value="1985">1985</option>
                                                <option value="1986">1986</option>
                                                <option value="1987">1987</option>
                                                <option value="1988">1988</option>
                                                <option value="1989">1989</option>
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                                <option value="1994">1994</option>
                                                <option value="1995">1995</option>
                                                <option value="1996">1996</option>
                                                <option value="1997">1997</option>
                                                <option value="1998">1998</option>
                                                <option value="1999">1999</option>
                                                <option value="2000">2000</option>
                                                <option value="2001">2001</option>
                                                <option value="2002">2002</option>
                                                <option value="2003">2003</option>
                                                <option value="2004">2004</option>
                                                <option value="2005">2005</option>
                                                <option value="2006">2006</option>
                                                <option value="2007">2007</option>
                                                <option value="2008">2008</option>
                                                <option value="2009">2009</option>
                                                <option value="2010">2010</option>
                                                <option value="2011">2011</option>
                                                <option value="2012">2012</option>
                                                <option value="2013">2013</option>
                                                <option value="2014">2014</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                            </select>
                                        </div>

                                        <div>

                                            <span className='label'>%age/GPA: </span>
                                            <select name="cgpa" id="custom-select" className='margin-left more-margin'
                                                
                                                onChange={handleEducationInputChange}>
                                                <option value="%age">%age</option>
                                                <option value="GPA">GPA</option>
                                            </select>
                                            <input name='cgpaObtained' type="text" id='custom-select' className='input-field' required
                                                value={educationFormData.cgpaObtained}
                                                onChange={handleEducationInputChange} />
                                        </div>



                                        <div className="form-row radio-buttons mt-1">
                                            <span className='label'>Degree Status: </span>
                                            <input type="radio" name="dismissed" id="completed" style={{ marginLeft: '70px' }} value="completed"
                                                onChange={handleEducationInputChange} />&nbsp;
                                            <label for="completed " >
                                                Completed
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="dismissed" id="progress" value="In Progress"
                                                onChange={handleEducationInputChange} />&nbsp;
                                            <label for="progress">
                                                In Progress
                                            </label>&nbsp;&nbsp;&nbsp;
                                            <input type="radio" name="dismissed" id="discontinued" value="Discontinued"
                                                onChange={handleEducationInputChange} />&nbsp;
                                            <label for="discontinued">
                                                Discontinued
                                            </label>
                                        </div>
                                        <div className="form-row">

                                            <span className='label'>Board/University: </span><input name='board' type="text" required className='input-field'
                                                value={educationFormData.board}
                                                onChange={handleEducationInputChange} />

                                        </div>
                                        <div className="form-row">
                                            <span className='label'>Country: </span>
                                            <select id="educountry" name="country" className=""
                                                value={educationFormData.country}
                                                onChange={handleEducationInputChange}>
                                                <option selected disabled>Choose...</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Åland Islands">Åland Islands</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="American Samoa">American Samoa</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Anguilla">Anguilla</option>
                                                <option value="Antarctica">Antarctica</option>
                                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Bouvet Island">Bouvet Island</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Christmas Island">Christmas Island</option>
                                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                <option value="Faroe Islands">Faroe Islands</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="French Guiana">French Guiana</option>
                                                <option value="French Polynesia">French Polynesia</option>
                                                <option value="French Southern Territories">French Southern Territories</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Greenland">Greenland</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guernsey">Guernsey</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-bissau">Guinea-bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Isle of Man">Isle of Man</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jersey">Jersey</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                <option value="Korea, Republic of">Korea, Republic of</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Macao">Macao</option>
                                                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Martinique">Martinique</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mayotte">Mayotte</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montenegro">Montenegro</option>
                                                <option value="Montserrat">Montserrat</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Namibia">Namibia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                <option value="New Caledonia">New Caledonia</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Niue">Niue</option>
                                                <option value="Norfolk Island">Norfolk Island</option>
                                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Pitcairn">Pitcairn</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Reunion">Reunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russian Federation">Russian Federation</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Saint Helena">Saint Helena</option>
                                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                <option value="Saint Lucia">Saint Lucia</option>
                                                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra Leone">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sri Lanka">Sri Lanka</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Timor-leste">Timor-leste</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tokelau">Tokelau</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Emirates">United Arab Emirates</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States">United States</option>
                                                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Viet Nam">Viet Nam</option>
                                                <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                <option value="Western Sahara">Western Sahara</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                        </div>

                                        <div >
                                            <span className='label'> Duration To: </span>
                                            <select name="durationToMonth" id="custom-select" className='more-margin'
                                                value={educationFormData.durationToMonth}
                                                onChange={handleEducationInputChange}>
                                                <option selected value=''>Month</option>
                                                <option value='1'>Janaury</option>
                                                <option value='2'>February</option>
                                                <option value='3'>March</option>
                                                <option value='4'>April</option>
                                                <option value='5'>May</option>
                                                <option value='6'>June</option>
                                                <option value='7'>July</option>
                                                <option value='8'>August</option>
                                                <option value='9'>September</option>
                                                <option value='10'>October</option>
                                                <option value='11'>November</option>
                                                <option value='12'>December</option>
                                            </select>
                                            <select id="custom-select" name="durationToYear"
                                                value={educationFormData.durationToYear}
                                                onChange={handleEducationInputChange}>
                                                <option selected>Year</option>
                                                <option value="1940">1940</option>
                                                <option value="1941">1941</option>
                                                <option value="1942">1942</option>
                                                <option value="1943">1943</option>
                                                <option value="1944">1944</option>
                                                <option value="1945">1945</option>
                                                <option value="1946">1946</option>
                                                <option value="1947">1947</option>
                                                <option value="1948">1948</option>
                                                <option value="1949">1949</option>
                                                <option value="1950">1950</option>
                                                <option value="1951">1951</option>
                                                <option value="1952">1952</option>
                                                <option value="1953">1953</option>
                                                <option value="1954">1954</option>
                                                <option value="1955">1955</option>
                                                <option value="1956">1956</option>
                                                <option value="1957">1957</option>
                                                <option value="1958">1958</option>
                                                <option value="1959">1959</option>
                                                <option value="1960">1960</option>
                                                <option value="1961">1961</option>
                                                <option value="1962">1962</option>
                                                <option value="1963">1963</option>
                                                <option value="1964">1964</option>
                                                <option value="1965">1965</option>
                                                <option value="1966">1966</option>
                                                <option value="1967">1967</option>
                                                <option value="1968">1968</option>
                                                <option value="1969">1969</option>
                                                <option value="1970">1970</option>
                                                <option value="1971">1971</option>
                                                <option value="1972">1972</option>
                                                <option value="1973">1973</option>
                                                <option value="1974">1974</option>
                                                <option value="1975">1975</option>
                                                <option value="1976">1976</option>
                                                <option value="1977">1977</option>
                                                <option value="1978">1978</option>
                                                <option value="1979">1979</option>
                                                <option value="1980">1980</option>
                                                <option value="1981">1981</option>
                                                <option value="1982">1982</option>
                                                <option value="1983">1983</option>
                                                <option value="1984">1984</option>
                                                <option value="1985">1985</option>
                                                <option value="1986">1986</option>
                                                <option value="1987">1987</option>
                                                <option value="1988">1988</option>
                                                <option value="1989">1989</option>
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                                <option value="1994">1994</option>
                                                <option value="1995">1995</option>
                                                <option value="1996">1996</option>
                                                <option value="1997">1997</option>
                                                <option value="1998">1998</option>
                                                <option value="1999">1999</option>
                                                <option value="2000">2000</option>
                                                <option value="2001">2001</option>
                                                <option value="2002">2002</option>
                                                <option value="2003">2003</option>
                                                <option value="2004">2004</option>
                                                <option value="2005">2005</option>
                                                <option value="2006">2006</option>
                                                <option value="2007">2007</option>
                                                <option value="2008">2008</option>
                                                <option value="2009">2009</option>
                                                <option value="2010">2010</option>
                                                <option value="2011">2011</option>
                                                <option value="2012">2012</option>
                                                <option value="2013">2013</option>
                                                <option value="2014">2014</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                            </select>
                                        </div>
                                        <div className="form-row mt-2">

                                            <span className='label'>Subject/Specialization: </span>
                                            <input name='subject' type="text" required className='input-field'
                                                value={educationFormData.subject}
                                                onChange={handleEducationInputChange} />
                                        </div>
                                        <div className="button-center">
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </form>

                                    <div className='container table-responsive'>
                                        <table className="table table-hover w-100 d-block d-md-table">
                                            <thead>
                                                <tr>
                                                    <th>Qualification</th>
                                                    <th>Degree Name</th>
                                                    <th>Duration From</th>
                                                    <th>Duration To</th>
                                                    <th>%age/GPA</th>
                                                    <th>Subject/Specialization</th>
                                                    <th>Board/University</th>
                                                </tr>
                                                <tr>
                                                    <td>Matric / OLevels / Equivalent</td>
                                                    <td>Computer Science</td>
                                                    <td>Mar-2021</td>
                                                    <td>Oct-2022</td>
                                                    <td>60</td>
                                                    <td>Biology</td>
                                                    <td>Peshawar Board</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UserProfile