import React from "react";
import "./footer.scss";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="container-fluid footer-section">
        <div className="container footer" >
          <nav className="row col-12 p-0 m-0 pt-4 pb-5" >

            <div className="col-lg-3 col-sm-12">
              <ul className="m-0 p-0">
                <h3>ABOUT ASAAN APPLY</h3>
                <li className="mt-4">
                  <Link to='/register' className="text-gray">
                    Sign In
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to='/about' className="text-gray">
                    About
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to='/career' className="text-gray">
                    Careers
                  </Link>
                </li>
                <li className="mt-2">
                  <a href="/" className="text-gray">
                    Legal
                  </a>
                </li>
                <li className="mt-2">
                  <a href="/" className="text-gray">
                    Reviews
                  </a>
                </li>
                <li className="mt-2">
                  <a href="/" className="text-gray">
                    Partner
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3  col-sm-12">
              <ul className="m-0 p-0">
                <h3>WEBSITE</h3>

                <li className="mt-4">
                  <Link to='/' className="text-gray">
                    Home
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="/jobs" className="text-gray">
                    Job news
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to='/career' className="text-gray">
                    Career
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-3  col-sm-12 ">
              <h3>Schedule a meeting</h3>
              <div className="footer-card">
                <h6>For urgent queries you can call us at </h6>
                <a href="/">+92-310-9533560</a>
                <p>OR</p>
                <div>Schedule a meeting</div>
              </div>
            </div> */}

          </nav>
        </div>
      </footer>
      <div className="container-fluid  " style={{backgroundColor:"#40adba"}}>
      <div className="footer-nav container ">
            <div className="container d-flex flex-column flex-md-row  justify-content-between align-items-center custom-footer">
              <div className="pt-1">Copyright © Asaan Apply 2024. All rights reserved.</div>
              <ul className="d-flex flex-row mt-2 justify-content-between align-items-center">
                <li>
                  <a href="/">
                    {" "}
                    <BsFacebook />
                  </a>
                </li>
                <li>
                  <a href="/">
                    {" "}
                    <BsTwitter />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <BsYoutube />
                  </a>
                </li>
                <li>
                  <a href="/">
                    {" "}
                    <BsLinkedin />
                  </a>
                </li>
              </ul>
              <ul className="d-flex flex-row justify-content-between mt-2 align-items-center">
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/">Retention Policy</a>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </>
  );
}

export default Footer;
