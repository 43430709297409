import React from 'react'
import "./_card.scss";

function Card() {
  let Cards = [
    {
      icon: "./images/card.svg",
      title: "Angular Developer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum illum eos."
    },
    {
      icon: "./images/card.svg",
      title: "React Developer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum illum eos."
    },
    {
      icon: "./images/card.svg",
      title: "Flutter Developer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum illum eos."
    },
    {
      icon: "./images/card.svg",
      title: "Java Developer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum illum eos."
    }
  ];
  const showCards = (Cards) => {
    return (
      <div className="col-12 mt-5 mb-5 card-pad  border border-rounded d-flex flex-column text-center align-items-center justify-content-center">
        <img src={Cards.icon} className='card-img-top round-img' alt="card" />
        <h5 className='card-title mb-3'>{Cards.title}</h5>
        <div className='description-box'>
        <p className='card-text'>{Cards.desc}</p>
        </div>
        <a href="#apply" className='btn btn-primary mt-2'>Apply Now</a>
      </div>
    );
  };
  return (
    <>
      <div className='container mt-5 container__margin'>
        <div className='row d-flex flex-row justify-content-center'>
          <h2 className='text-center colors__text'>Our Latest Jobs</h2>
          <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center flex-wrap'>
          {Cards.map(showCards)}
          </div>
        </div>
      </div>
    </>
  );
}
export default Card;

