import React, { useState, useEffect } from "react";
import "./_stepper.scss";
import {
  userInformation,
  educationInformation,
  getUserData,
  fetchEducationData,
  clearMessage,

} from "../../Services/Reducers/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Stepper = () => {
  const { successMessage, userData, educationDataa, step } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEducationData());
  }, [dispatch])
  const navigate = useNavigate()
  useEffect(() => {
    if (step == null) {
      document.getElementById("v-pills-Personal-tab").click();
    } else if (step == 1) {
      document.getElementById("v-pills-profile-tab").click();
    }
    else if (step == 2) {
      document.getElementById("v-pills-messages-tab").click();
    }
    else if (step == 3) {
      document.getElementById("v-pills-settings-tab").click();
    }
    else if (step == 0) {
      navigate("/pendingApproval")
    }
  }, [step])

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    country: "",
    birthPlace: "",
    maritalStatus: "",
    religion: "",
    sector: "",
    cnic: "",
    height: "",
    wight: "",
    bloodGroup: "",
    passportNo: "",
    typeOfPassport: "",
    passportIssuance: "",
    passportPlaceIssuance: "",
    primaryEmailAddress: "",
    ConfirmEmailAddress: "",
    OtherEmailAddress: "",
    mobileNumber: "",
    ConfirmMobileNumber: "",
    OtherContactNumber: "",
    nationality: "",
    foreignNational: "",
    province: "",
    city: "",
    domicile: "",
    zone: "",
    permanentAddress: "",
    postalAddress: "",
    postalCode: "",
    fatherName: "",
    fatherAlive: "",
    fatherCnic: "",
    fatherOccupation: "",
    fatherEmployer: "",
    fatherContact: "",
    fatherDesignation: "",
    fatherIncome: "",
    fatherEducation: "",
    motherName: "",
    motherCnic: "",
    motherOccupation: "",
    motherDesignation: "",
    motherIncome: 0,
    motherEducation: "",
    // fatherAlive: "",
    NoOfSiblings: 0,
    relationOfSiblings: "",
    governmentEmployee: "",
    currentOccupation: "",
    currentDesignation: "",
    hafizEQuran: "",
    ageRelaxation: "",
    desiredTestCity1: "",
    desiredTestCity2: "",
    physicalDisability: "",
    needAPerson: "",
    convicted: "",
    dismissed: "",
    abroadDetails: "",
    OtherInormation: "",
    AliveDeparted: "",
    AliveDepartedMother: "",
  });

  const [educationData, seteducationData] = useState({
    qualification: "",
    name: "",
    durationFrom: "",
    percentOrGpa: "",
    marks: "",
    degreeStatus: "",
    boardOrUniversity: "",
    country: "",
    durationTo: "",
    subjectSpecialization: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...userData,
      }));
    }
  }, [userData]);
  useEffect(() => {
    if (educationDataa) {
      seteducationData(educationDataa[0]);
    }
    clearMessage()
  }, [educationDataa]);




  const handleChange = (e) => {
    const { name, type, value, files } = e.target;


    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formo = new FormData();
    console.log("Preparing to submit");


    for (const [key, value] of Object.entries(formData)) {

      if (value instanceof File) {
        formo.append(key, value);
      } else {

        formo.append(key, value);
      }
    }

    // Dispatch the FormData
    dispatch(userInformation(formo));
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    seteducationData({
      ...educationData,
      [name]: value,
    });
  };
  const eductionHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(educationInformation(educationData));
  };

  return (
    <div>
      <div className="bg">
        <div className="container">
          <a href="#" className="navbar-brand nav-margin">
            <img
              className="logo"
              src="./images/logo-png.png"
              alt="white logo"
            />
          </a>
        </div>
      </div>

      <div className="container pb-5 pt-2 accordion ">
        <nav className="navbar navbar-expand-lg">
          <div className="container menu p-0 m-0">
            <button
              className="navbar-toggler hambur"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-column  flex-lg-row">
                <li className="nav-item">
                  <a className="nav-link active " aria-current="page" href="#">
                    Personal Settings
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " aria-current="page" href="#">
                    Application Information
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " aria-current="page" href="#">
                    Application Status
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " aria-current="page" href="#">
                    Scholarship
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className="container pb-4">
        <div className="col-12 d-flex flex-column flex-md-row align-items-start">
          <div
            className="col-12 col-md-2 border  nav flex-column nav-pills backcolor"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active outline"
              id="v-pills-Personal-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Personal"
              type="button"
              role="tab"
              aria-controls="v-pills-Personal"
              aria-selected="true"
            >
              Personal Information
            </button>
            <button
              className="nav-link outline "
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Guardian Information
            </button>
            <button
              className="nav-link outline "
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              Occupation
            </button>
            <button
              className="nav-link outline "
              id="v-pills-settings-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-settings"
              type="button"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
            >
              Education Background
            </button>
          </div>

          <div
            className="col-12 col-md-10 tab-content p-0 border bg-light  "
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane fade show active width-size"
              id="v-pills-Personal"
              role="tabpanel"
              aria-labelledby="v-pills-Personal-tab"
              tabIndex="0"
            >
              <div className="container">
                <form action="#" className="container input-padding bg-light">
                  <div className="span-padding line-bottom">
                    <span className="">
                      Please fill in your basic personal information:
                    </span>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">First Name:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="First name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Last Name:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="Last name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Gender: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <select
                        className="form-select w-100 m-0"
                        required
                        id="inputGroupSelect02"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Date of Birth</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="date"
                        required
                        className="form-control m-0 w-full"
                        name="dateOfBirth"
                        id="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Country: </span>{" "}
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <select
                        className="form-select w-100 m-0"
                        required
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                        <option value="Afghanistan">Afghanistan</option>{" "}
                        <option value="Åland Islands">Åland Islands</option>{" "}
                        <option value="Albania">Albania</option>{" "}
                        <option value="Algeria">Algeria</option>{" "}
                        <option value="American Samoa">American Samoa</option>{" "}
                        <option value="Andorra">Andorra</option>{" "}
                        <option value="Angola">Angola</option>{" "}
                        <option value="Anguilla">Anguilla</option>{" "}
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                        <option value="Afghanistan">Afghanistan</option>{" "}
                        <option value="Åland Islands">Åland Islands</option>{" "}
                        <option value="Albania">Albania</option>{" "}
                        <option value="Algeria">Algeria</option>{" "}
                        <option value="American Samoa">American Samoa</option>{" "}
                        <option value="Andorra">Andorra</option>{" "}
                        <option value="Angola">Angola</option>{" "}
                        <option value="Anguilla">Anguilla</option>{" "}
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>


                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className=" span">Birth place: </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 w-full"
                        name="birthPlace"
                        id="date"
                        value={formData.birthPlace}
                        onChange={handleChange}
                      />
                    </div>

                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Marital Status: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <select
                        id="marital"
                        name="maritalStatus"
                        className="form-select w-full m-0"
                        value={formData.maritalStatus}
                        onChange={handleChange}
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">* Religion: </span>
                    </div>
                    <div className="col-12 col-md">
                      <select
                        className="form-control m-0 dropdown w-100 m-0"
                        id="religion"
                        name="religion"
                        value={formData.religion}
                        onChange={handleChange}
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="African Traditional &amp; Diasporic">
                          African Traditional &amp; Diasporic
                        </option>
                        <option value="Agnostic">Agnostic</option>
                        <option value="Atheist">Atheist</option>
                        <option value="Baha'i">Baha'i</option>
                        <option value="Buddhism">Buddhism</option>
                        <option value="Cao Dai">Cao Dai</option>
                        <option value="Chinese traditional religion">
                          Chinese traditional religion
                        </option>
                        <option value="Christianity">Christianity</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Islam">Islam</option>
                        <option value="Jainism">Jainism</option>
                        <option value="Juche">Juche</option>
                        <option value="Judaism">Judaism</option>
                        <option value="Neo-Paganism">Neo-Paganism</option>
                        <option value="Nonreligious">Nonreligious</option>
                        <option value="Rastafarianism">Rastafarianism</option>
                        <option value="Secular">Secular</option>
                        <option value="Shinto">Shinto</option>
                        <option value="Sikhism">Sikhism</option>
                        <option value="Spiritism">Spiritism</option>
                        <option value="Tenrikyo">Tenrikyo</option>
                        <option value="Unitarian-Universalism">
                          Unitarian-Universalism
                        </option>
                        <option value="Zoroastrianism">Zoroastrianism</option>
                        <option value="primal-indigenous">
                          primal-indigenous
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <br />


                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Sector:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="First name"
                        name="sector"
                        value={formData.sector}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">CINC:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="number"
                        className="form-control m-0"
                        aria-label="SNIC"
                        name="cnic"
                        id="CNIC"
                        value={formData.cnic}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Height:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="name"
                        name="height"
                        id="height"
                        value={formData.height}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">* Weight</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="SNIC"
                        name="weight"
                        id="Weight"
                        value={formData.weight}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Blood group: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="name"
                        name="bloodGroup"
                        id="blood"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">* Passport No.</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0"
                        aria-label="SNIC"
                        name="passportNo"
                        id="Weight"
                        value={formData.passportNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Type of Passport: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        name="typeOfPassport"
                        id="typeOfPassport"
                        value={formData.typeOfPassport}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        * Passport issuance
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="date"
                        className="form-control m-0"
                        name="passportIssuance"
                        id="passportIssuance"
                        value={formData.passportIssuance}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Passport Place issuance </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        name="passportPlaceIssuance"
                        id="passportPlaceIssuance"
                        value={formData.passportPlaceIssuance}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Primary Email address
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="email"
                        className="form-control m-0"
                        name="primaryEmailAddress"
                        id="primaryEmailAddress"
                        value={formData.primaryEmailAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Confirm email address </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="email"
                        className="form-control m-0"
                        name="ConfirmEmailAddress"
                        id="ConfirmEmailAddress"
                        value={formData.ConfirmEmailAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        * Other email address
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="email"
                        className="form-control m-0"
                        name="OtherEmailAddress"
                        id="OtherEmailAddress"
                        value={formData.OtherEmailAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Mobile number </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="tel"
                        className="form-control m-0"
                        name="mobileNumber"
                        id="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Confirm mobile number
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="tel"
                        className="form-control m-0"
                        name="ConfirmMobileNumber"
                        id="ConfirmMobileNumber"
                        value={formData.ConfirmMobileNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">* Other contact number: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="tel"
                        className="form-control m-0"
                        name="OtherContactNumber"
                        id="OtherContactNumber"
                        value={formData.OtherContactNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Nationality:</span>
                    </div>
                    <div className="col-12 col-md">
                      <select
                        id="nationality"
                        name="nationality"
                        value={formData.nationality}
                        onChange={handleChange}
                        className="form-select m-0"
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                  </div>

                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">
                        Are you a dual/foreign national (Yes/No)
                      </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="foreignNational"
                        id="foreignNationalYes"
                        value="Yes"
                        checked={formData?.foreignNational === "Yes"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="foreignNationalYes"
                      >
                        Yes
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        className="form-check-input"
                        type="radio"
                        name="foreignNational"
                        id="foreignNationalNo"
                        value="No"
                        checked={formData?.foreignNational === "No"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="foreignNationalNo"
                      >
                        No
                      </label>
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Province:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0"
                        required
                        name="province"
                        id="province"
                        value={formData.province}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">City: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        required
                        name="city"
                        id="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Domicile:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="file"
                        className="form-control m-0 file w-100"
                        id="domicile"
                        name="domicile"
                        // value={formData.domicile}
                        onChange={handleChange}
                      />
                    </div>
                  </div>



                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Zone: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        required
                        name="zone"
                        id="zone"
                        value={formData.zone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Permanent address:
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0 w-100"
                        id="permanentAddress"
                        name="permanentAddress"
                        value={formData.permanentAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Postal address: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0"
                        required
                        name="postalAddress"
                        id="postalAddress"
                        value={formData.postalAddress}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Postal code:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="number"
                        className="form-control m-0 w-100"
                        id="postalCode"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary ms-4"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
              tabIndex="0"
            >
              <div className="container">
                <form action="" className="container input-padding bg-light">
                  <div className="span-padding line-bottom">
                    <span className="fs-5">
                      Please fill Guardian information:
                      <br />
                    </span>
                  </div>
                  <br />
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Father Name: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control m-0 "
                        name="fatherName"
                        id="fatherName"
                        value={formData.fatherName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Alive/departed:{" "}
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control m-0 "
                        name="fatherAlive"
                        id="fatherAlive"
                        value={formData.fatherAlive}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Father CNIC: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="number"
                        className="form-control m-0 "
                        name="fatherCnic"
                        id="fatherCnic"
                        value={formData.fatherCnic}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Father occupation:
                      </span>
                    </div>
                    <div className="col-12 col-md">

                      <input type="text" className="form-control m-0  "

                        name="fatherOccupation"
                        id="fatherOccupation"
                        value={formData.fatherOccupation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />



                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Father employer: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="fatherEmployer"
                        id="fatherEmployer"
                        value={formData.fatherEmployer}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Father contact:</span>
                    </div>
                    <div className="col-12 col-md">

                      <input
                        type="tel"
                        className="form-control m-0 "

                        name="fatherContact"
                        id="fatherContact"
                        value={formData.fatherContact}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Father designation: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="fatherDesignation"
                        id="fatherDesignation"
                        value={formData.fatherDesignation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Father income:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="fatherIncome"
                        id="fatherIncome"
                        value={formData.fatherIncome}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Father education:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="fatherEducation"
                        id="fatherEducation"
                        value={formData.fatherEducation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Mother name:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherName"
                        id="motherName"
                        value={formData.motherName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Alive/departed:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherAlive"
                        id="motherAlive"
                        value={formData.motherAlive}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">Mother CNIC:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherCnic"
                        id="motherCnic"
                        value={formData.motherCnic}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width ">
                      <span className="span">Mother occupation:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherOccupation"
                        id="motherOccupation"
                        value={formData.motherOccupation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Mother designation:
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherDesignation"
                        id="motherDesignation"
                        value={formData.motherDesignation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">Mother income:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherIncome"
                        id="motherIncome"
                        value={formData.motherIncome}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Mother education:
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="motherEducation"
                        id="motherEducation"
                        value={formData.motherEducation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <br />

                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="span">No of Siblings:</span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="number"
                        required
                        className="form-control m-0 "
                        name="NoOfSiblings"
                        id="NoOfSiblings"
                        value={formData.NoOfSiblings}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin span">
                        Names and relation of siblings:
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        required
                        className="form-control m-0 "
                        name="relationOfSiblings"
                        id="relationOfSiblings"
                        value={formData.relationOfSiblings}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
              tabIndex="0"
            >

              <div className="tab-content col-12 p-0 container">
                <form action="" className="container input-padding bg-light">
                  <div className="span-padding line-bottom">
                    <span className="fs-5">
                      Please fill Occupation information:
                      <br />
                    </span>
                  </div>
                  <br />
                  <br />
                  <span>
                    Are you a government employee, if yes date of entry into
                    first government service (Yes/No)
                  </span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="governmentEmployee"
                    id="governmentEmployeeYes"
                    value="Yes"
                    checked={formData.governmentEmployee === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="governmentEmployeeYes"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="governmentEmployee"
                    id="governmentEmployeeNo"
                    value="No"
                    checked={formData.governmentEmployee === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="governmentEmployeeNo"
                  >
                    No
                  </label>
                  <br />
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0">
                      <span>Current occupation: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">

                      <input
                        type="text"
                        className="form-control w-100"
                        name="currentOccupation"
                        id="currentOccupation"

                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0">
                      <span className="input-margin">
                        Current designation:{" "}
                      </span>
                    </div>
                    <div className="col-12 col-md">

                      <input
                        type="text"
                        className="form-control w-100"
                        name="currentDesignation"
                        id="currentDesignation"
                        value={formData?.currentDesignation}

                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <span>Are you a hafiz e Quran (Yes/No)</span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hafizEQuran"
                    id="hafizEQuranYes"
                    value="Yes"
                    checked={formData?.hafizEQuran === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="hafizEQuranYes">
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hafizEQuran"
                    id="hafizEQuranNo"
                    value="No"
                    checked={formData?.hafizEQuran === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="hafizEQuranNo">
                    No
                  </label>
                  <br />
                  <br />
                  <span>Do you claim age relaxation(Yes/No)</span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="ageRelaxation"
                    id="ageRelaxationYes"
                    value="Yes"
                    checked={formData?.ageRelaxation === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="ageRelaxationYes"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="ageRelaxation"
                    id="ageRelaxationNo"
                    value="No"
                    checked={formData?.ageRelaxation === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="ageRelaxationNo">
                    No
                  </label>
                  <br />
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0">
                      <span>Desired test city1: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="desiredTestCity1"
                        id="desiredTestCity1"
                        value={formData.desiredTestCity1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0">
                      <span className="input-margin">Desired test city2:</span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="desiredTestCity2"
                        id="desiredTestCity2"
                        value={formData.desiredTestCity2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <br />
                  <span className="mt-lg-3 mt-0">
                    Do you claim physical disability (Yes/No)
                  </span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="physicalDisability"
                    id="flexRadioDefault9"
                    value="Yes"
                    checked={formData?.physicalDisability === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault9"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="physicalDisability"
                    id="flexRadioDefault10"
                    value="No"
                    checked={formData?.physicalDisability === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault10"
                  >
                    No
                  </label>
                  <br />
                  <br />
                  <span>
                    Nature of disability/do you need a person for test (Yes/No)
                  </span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="needAPerson"
                    id="flexRadioDefault11"
                    value="Yes"
                    checked={formData?.needAPerson === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault11"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="needAPerson"
                    id="flexRadioDefault12"
                    value="No"
                    checked={formData?.needAPerson === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault12"
                  >
                    No
                  </label>
                  <br />
                  <br />
                  <span>Are you convicted (Yes/No)</span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="convicted"
                    id="flexRadioDefault13"
                    value="Yes"
                    checked={formData?.convicted === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault13"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="convicted"
                    id="flexRadioDefault14"
                    value="No"
                    checked={formData?.convicted === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault14"
                  >
                    No
                  </label>
                  <br />
                  <br />
                  <span>
                    Have you been dismissed/removed from service under federal
                    or provincial government? (Yes/No)
                  </span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="dismissed"
                    id="flexRadioDefault16"
                    value="Yes"
                    checked={formData?.dismissed === "Yes"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault16"
                  >
                    Yes
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="dismissed"
                    id="flexRadioDefault17"
                    value="No"
                    checked={formData?.dismissed === "No"}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault17"
                  >
                    No
                  </label>
                  <br />
                  <br />
                  <span>Write details if you have been abroad</span>
                  <textarea
                    className="form-control"
                    id="abroadDetails"
                    name="abroadDetails"
                    rows="3"
                    value={formData.abroadDetails}
                    onChange={handleChange}
                  ></textarea>
                  <br />
                  <br />
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-settings"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
              tabIndex="0"
            >
              <div className="container">
                <form action="" className="container input-padding bg-light">
                  <div className="span-padding line-bottom">
                    <span className="fs-5">
                      Please fill Education background:
                      <br />
                    </span>
                  </div>
                  <br />
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span>Qualification: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <select
                        name="qualification"
                        id="qualification"
                        className="form-control w-100 m-0"
                        value={educationData?.qualification}
                        onChange={handleInputChange}
                      >
                        <option value="Under Matric">Under Matric</option>
                        <option value="Matric/ OLevels / Equivalent">
                          Matric / OLevels / Equivalent
                        </option>
                        <option value="Intermediate / A Level / Equivalent">
                          Intermediate / A Level / Equivalent
                        </option>
                        <option value="BA / BSc / BE / BS / BBA / MBBS / BDS / Equivalent">
                          BA / BSc / BE / BS / BBA / MBBS / BDS / Equivalent
                        </option>
                        <option value="MSc / MS / MBA / MCPS / Equivalent">
                          MSc / MS / MBA / MCPS / Equivalent
                        </option>
                        <option value="MPhil">MPhil</option>
                        <option value="PhD / FCPS / FRCS / MRCP / Equivalent">
                          PhD / FCPS / FRCS / MRCP / Equivalent
                        </option>
                        <option value="Postdoctoral">Postdoctoral</option>
                        <option value="certificate">Certificate</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span>Name: </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="name"
                        value={educationData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span>Duration From: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="date"
                        className="form-control w-100"
                        name="durationFrom"
                        value={educationData?.durationFrom}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width d-flex justify-content-between align-items-center">
                      <span>%age/GPA: </span>
                      <select
                        name="percentOrGpa"
                        id="percentOrGpa"
                        className="form-control duration w-50"
                        value={educationData?.percentOrGpa}
                        onChange={handleInputChange}
                      >
                        <option value="%age">%age</option>
                        <option value="GPA">GPA</option>
                      </select>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="marks"
                        value={educationData?.marks}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <br />
                  <span>Degree Status: </span>
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    name="degreeStatus"
                    id="completed"
                    value="Completed"
                    checked={educationData?.degreeStatus === "Completed"}
                    onChange={handleInputChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="completed">
                    Completed
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="degreeStatus"
                    id="progress"
                    value="In Progress"
                    checked={educationData?.degreeStatus === "In Progress"}
                    onChange={handleInputChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="progress">
                    In Progress
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="form-check-input"
                    type="radio"
                    name="degreeStatus"
                    id="discontinued"
                    value="Discontinued"
                    checked={educationData?.degreeStatus === "Discontinued"}
                    onChange={handleInputChange}
                  />
                  &nbsp;
                  <label className="form-check-label" htmlFor="discontinued">
                    Discontinued
                  </label>
                  <br />
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span>Board/University: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="text"
                        required
                        className="form-control w-100"
                        name="boardOrUniversity"
                        value={educationData?.boardOrUniversity}
                        onChange={handleInputChange}
                      />
                    </div>



                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin">Country: </span>
                    </div>
                    <div className="col-12 col-md">
                      <select
                        id="educountry"
                        name="country"
                        className="form-select w-100 m-0"
                        value={educationData?.country}
                        onChange={handleInputChange}
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                        <option value="Afghanistan">Afghanistan</option>{" "}
                        <option value="Åland Islands">Åland Islands</option>{" "}
                        <option value="Albania">Albania</option>{" "}
                        <option value="Algeria">Algeria</option>{" "}
                        <option value="American Samoa">American Samoa</option>{" "}
                        <option value="Andorra">Andorra</option>{" "}
                        <option value="Angola">Angola</option>{" "}
                        <option value="Anguilla">Anguilla</option>{" "}
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                        <option value="Afghanistan">Afghanistan</option>{" "}
                        <option value="Åland Islands">Åland Islands</option>{" "}
                        <option value="Albania">Albania</option>{" "}
                        <option value="Algeria">Algeria</option>{" "}
                        <option value="American Samoa">American Samoa</option>{" "}
                        <option value="Andorra">Andorra</option>{" "}
                        <option value="Angola">Angola</option>{" "}
                        <option value="Anguilla">Anguilla</option>{" "}
                        <option value="Antarctica">Antarctica</option>{" "}
                        <option value="Antigua and Barbuda">
                          {" "}
                          Antigua and Barbuda{" "}
                        </option>{" "}
                        <option value="Argentina">Argentina</option>{" "}
                        <option value="Armenia">Armenia</option>{" "}
                        <option value="Aruba">Aruba</option>{" "}
                        <option value="Australia">Australia</option>{" "}
                        <option value="Austria">Austria</option>{" "}
                        <option value="Azerbaijan">Azerbaijan</option>{" "}
                        <option value="Bahamas">Bahamas</option>{" "}
                        <option value="Bahrain">Bahrain</option>{" "}
                        <option value="Bangladesh">Bangladesh</option>{" "}
                        <option value="Barbados">Barbados</option>{" "}
                        <option value="Belarus">Belarus</option>{" "}
                        <option value="Belgium">Belgium</option>{" "}
                        <option value="Belize">Belize</option>{" "}
                        <option value="Benin">Benin</option>{" "}
                        <option value="Bermuda">Bermuda</option>{" "}
                        <option value="Bhutan">Bhutan</option>{" "}
                        <option value="Bolivia">Bolivia</option>{" "}
                        <option value="Bosnia and Herzegovina">
                          {" "}
                          Bosnia and Herzegovina{" "}
                        </option>{" "}
                        <option value="Botswana">Botswana</option>{" "}
                        <option value="Bouvet Island">Bouvet Island</option>{" "}
                        <option value="Brazil">Brazil</option>{" "}
                        <option value="British Indian Ocean Territory">
                          {" "}
                          British Indian Ocean Territory{" "}
                        </option>{" "}
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>{" "}
                        <option value="Bulgaria">Bulgaria</option>{" "}
                        <option value="Burkina Faso">Burkina Faso</option>{" "}
                        <option value="Burundi">Burundi</option>{" "}
                        <option value="Cambodia">Cambodia</option>{" "}
                        <option value="Cameroon">Cameroon</option>{" "}
                        <option value="Canada">Canada</option>{" "}
                        <option value="Cape Verde">Cape Verde</option>{" "}
                        <option value="Cayman Islands">Cayman Islands</option>{" "}
                        <option value="Central African Republic">
                          {" "}
                          Central African Republic{" "}
                        </option>{" "}
                        <option value="Chad">Chad</option>{" "}
                        <option value="Chile">Chile</option>{" "}
                        <option value="China">China</option>{" "}
                        <option value="Christmas Island">
                          Christmas Island
                        </option>{" "}
                        <option value="Cocos (Keeling) Islands">
                          {" "}
                          Cocos (Keeling) Islands{" "}
                        </option>{" "}
                        <option value="Colombia">Colombia</option>{" "}
                        <option value="Comoros">Comoros</option>{" "}
                        <option value="Congo">Congo</option>{" "}
                        <option value="Congo, The Democratic Republic of The">
                          {" "}
                          Congo, The Democratic Republic of The{" "}
                        </option>{" "}
                        <option value="Cook Islands">Cook Islands</option>{" "}
                        <option value="Costa Rica">Costa Rica</option>{" "}
                        <option value="Cote D'ivoire">Cote D'ivoire</option>{" "}
                        <option value="Croatia">Croatia</option>{" "}
                        <option value="Cuba">Cuba</option>{" "}
                        <option value="Cyprus">Cyprus</option>{" "}
                        <option value="Czech Republic">Czech Republic</option>{" "}
                        <option value="Denmark">Denmark</option>{" "}
                        <option value="Djibouti">Djibouti</option>{" "}
                        <option value="Dominica">Dominica</option>{" "}
                        <option value="Dominican Republic">
                          {" "}
                          Dominican Republic{" "}
                        </option>{" "}
                        <option value="Ecuador">Ecuador</option>{" "}
                        <option value="Egypt">Egypt</option>{" "}
                        <option value="El Salvador">El Salvador</option>{" "}
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>{" "}
                        <option value="Eritrea">Eritrea</option>{" "}
                        <option value="Estonia">Estonia</option>{" "}
                        <option value="Ethiopia">Ethiopia</option>{" "}
                        <option value="Falkland Islands (Malvinas)">
                          {" "}
                          Falkland Islands (Malvinas){" "}
                        </option>{" "}
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>{" "}
                        <option value="Fiji">Fiji</option>{" "}
                        <option value="Finland">Finland</option>{" "}
                        <option value="France">France</option>{" "}
                        <option value="French Guiana">French Guiana</option>{" "}
                        <option value="French Polynesia">
                          French Polynesia
                        </option>{" "}
                        <option value="French Southern Territories">
                          {" "}
                          French Southern Territories{" "}
                        </option>{" "}
                        <option value="Gabon">Gabon</option>{" "}
                        <option value="Gambia">Gambia</option>{" "}
                        <option value="Georgia">Georgia</option>{" "}
                        <option value="Germany">Germany</option>{" "}
                        <option value="Ghana">Ghana</option>{" "}
                        <option value="Gibraltar">Gibraltar</option>{" "}
                        <option value="Greece">Greece</option>{" "}
                        <option value="Greenland">Greenland</option>{" "}
                        <option value="Grenada">Grenada</option>{" "}
                        <option value="Guadeloupe">Guadeloupe</option>{" "}
                        <option value="Guam">Guam</option>{" "}
                        <option value="Guatemala">Guatemala</option>{" "}
                        <option value="Guernsey">Guernsey</option>{" "}
                        <option value="Guinea">Guinea</option>{" "}
                        <option value="Guinea-bissau">Guinea-bissau</option>{" "}
                        <option value="Guyana">Guyana</option>{" "}
                        <option value="Haiti">Haiti</option>{" "}
                        <option value="Heard Island and Mcdonald Islands">
                          {" "}
                          Heard Island and Mcdonald Islands{" "}
                        </option>{" "}
                        <option value="Holy See (Vatican City State)">
                          {" "}
                          Holy See (Vatican City State){" "}
                        </option>{" "}
                        <option value="Honduras">Honduras</option>{" "}
                        <option value="Hong Kong">Hong Kong</option>{" "}
                        <option value="Hungary">Hungary</option>{" "}
                        <option value="Iceland">Iceland</option>{" "}
                        <option value="India">India</option>{" "}
                        <option value="Indonesia">Indonesia</option>{" "}
                        <option value="Iran, Islamic Republic of">
                          {" "}
                          Iran, Islamic Republic of{" "}
                        </option>{" "}
                        <option value="Iraq">Iraq</option>{" "}
                        <option value="Ireland">Ireland</option>{" "}
                        <option value="Isle of Man">Isle of Man</option>{" "}
                        <option value="Israel">Israel</option>{" "}
                        <option value="Italy">Italy</option>{" "}
                        <option value="Jamaica">Jamaica</option>{" "}
                        <option value="Japan">Japan</option>{" "}
                        <option value="Jersey">Jersey</option>{" "}
                        <option value="Jordan">Jordan</option>{" "}
                        <option value="Kazakhstan">Kazakhstan</option>{" "}
                        <option value="Kenya">Kenya</option>{" "}
                        <option value="Kiribati">Kiribati</option>{" "}
                        <option value="Korea, Democratic People's Republic of">
                          {" "}
                          Korea, Democratic People's Republic of{" "}
                        </option>{" "}
                        <option value="Korea, Republic of">
                          {" "}
                          Korea, Republic of{" "}
                        </option>{" "}
                        <option value="Kuwait">Kuwait</option>{" "}
                        <option value="Kyrgyzstan">Kyrgyzstan</option>{" "}
                        <option value="Lao People's Democratic Republic">
                          {" "}
                          Lao People's Democratic Republic{" "}
                        </option>{" "}
                        <option value="Latvia">Latvia</option>{" "}
                        <option value="Lebanon">Lebanon</option>{" "}
                        <option value="Lesotho">Lesotho</option>{" "}
                        <option value="Liberia">Liberia</option>{" "}
                        <option value="Libyan Arab Jamahiriya">
                          {" "}
                          Libyan Arab Jamahiriya{" "}
                        </option>{" "}
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>{" "}
                        <option value="Lithuania">Lithuania</option>{" "}
                        <option value="Luxembourg">Luxembourg</option>{" "}
                        <option value="Macao">Macao</option>{" "}
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          {" "}
                          Macedonia, The Former Yugoslav Republic of{" "}
                        </option>{" "}
                        <option value="Madagascar">Madagascar</option>{" "}
                        <option value="Malawi">Malawi</option>{" "}
                        <option value="Malaysia">Malaysia</option>{" "}
                        <option value="Maldives">Maldives</option>{" "}
                        <option value="Mali">Mali</option>{" "}
                        <option value="Malta">Malta</option>{" "}
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>{" "}
                        <option value="Martinique">Martinique</option>{" "}
                        <option value="Mauritania">Mauritania</option>{" "}
                        <option value="Mauritius">Mauritius</option>{" "}
                        <option value="Mayotte">Mayotte</option>{" "}
                        <option value="Mexico">Mexico</option>{" "}
                        <option value="Micronesia, Federated States of">
                          {" "}
                          Micronesia, Federated States of{" "}
                        </option>{" "}
                        <option value="Moldova, Republic of">
                          {" "}
                          Moldova, Republic of{" "}
                        </option>{" "}
                        <option value="Monaco">Monaco</option>{" "}
                        <option value="Mongolia">Mongolia</option>{" "}
                        <option value="Montenegro">Montenegro</option>{" "}
                        <option value="Montserrat">Montserrat</option>{" "}
                        <option value="Morocco">Morocco</option>{" "}
                        <option value="Mozambique">Mozambique</option>{" "}
                        <option value="Myanmar">Myanmar</option>{" "}
                        <option value="Namibia">Namibia</option>{" "}
                        <option value="Nauru">Nauru</option>{" "}
                        <option value="Nepal">Nepal</option>{" "}
                        <option value="Netherlands">Netherlands</option>{" "}
                        <option value="Netherlands Antilles">
                          {" "}
                          Netherlands Antilles{" "}
                        </option>{" "}
                        <option value="New Caledonia">
                          New Caledonia
                        </option>{" "}
                        <option value="New Zealand">New Zealand</option>{" "}
                        <option value="Nicaragua">Nicaragua</option>{" "}
                        <option value="Niger">Niger</option>{" "}
                        <option value="Nigeria">Nigeria</option>{" "}
                        <option value="Niue">Niue</option>{" "}
                        <option value="Norfolk Island">Norfolk Island</option>{" "}
                        <option value="Northern Mariana Islands">
                          {" "}
                          Northern Mariana Islands{" "}
                        </option>{" "}
                        <option value="Norway">Norway</option>{" "}
                        <option value="Oman">Oman</option>{" "}
                        <option value="Pakistan">Pakistan</option>{" "}
                        <option value="Palau">Palau</option>{" "}
                        <option value="Palestinian Territory, Occupied">
                          {" "}
                          Palestinian Territory, Occupied{" "}
                        </option>{" "}
                        <option value="Panama">Panama</option>{" "}
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>{" "}
                        <option value="Paraguay">Paraguay</option>{" "}
                        <option value="Peru">Peru</option>{" "}
                        <option value="Philippines">Philippines</option>{" "}
                        <option value="Pitcairn">Pitcairn</option>{" "}
                        <option value="Poland">Poland</option>{" "}
                        <option value="Portugal">Portugal</option>{" "}
                        <option value="Puerto Rico">Puerto Rico</option>{" "}
                        <option value="Qatar">Qatar</option>{" "}
                        <option value="Reunion">Reunion</option>{" "}
                        <option value="Romania">Romania</option>{" "}
                        <option value="Russian Federation">
                          {" "}
                          Russian Federation{" "}
                        </option>{" "}
                        <option value="Rwanda">Rwanda</option>{" "}
                        <option value="Saint Helena">Saint Helena</option>{" "}
                        <option value="Saint Kitts and Nevis">
                          {" "}
                          Saint Kitts and Nevis{" "}
                        </option>{" "}
                        <option value="Saint Lucia">Saint Lucia</option>{" "}
                        <option value="Saint Pierre and Miquelon">
                          {" "}
                          Saint Pierre and Miquelon{" "}
                        </option>{" "}
                        <option value="Saint Vincent and The Grenadines">
                          {" "}
                          Saint Vincent and The Grenadines{" "}
                        </option>{" "}
                        <option value="Samoa">Samoa</option>{" "}
                        <option value="San Marino">San Marino</option>{" "}
                        <option value="Sao Tome and Principe">
                          {" "}
                          Sao Tome and Principe{" "}
                        </option>{" "}
                        <option value="Saudi Arabia">Saudi Arabia</option>{" "}
                        <option value="Senegal">Senegal</option>{" "}
                        <option value="Serbia">Serbia</option>{" "}
                        <option value="Seychelles">Seychelles</option>{" "}
                        <option value="Sierra Leone">Sierra Leone</option>{" "}
                        <option value="Singapore">Singapore</option>{" "}
                        <option value="Slovakia">Slovakia</option>{" "}
                        <option value="Slovenia">Slovenia</option>{" "}
                        <option value="Solomon Islands">Solomon Islands</option>{" "}
                        <option value="Somalia">Somalia</option>{" "}
                        <option value="South Africa">South Africa</option>{" "}
                        <option value="South Georgia and The South Sandwich Islands">
                          {" "}
                          South Georgia and The South Sandwich Islands{" "}
                        </option>{" "}
                        <option value="Spain">Spain</option>{" "}
                        <option value="Sri Lanka">Sri Lanka</option>{" "}
                        <option value="Sudan">Sudan</option>{" "}
                        <option value="Suriname">Suriname</option>{" "}
                        <option value="Svalbard and Jan Mayen">
                          {" "}
                          Svalbard and Jan Mayen{" "}
                        </option>{" "}
                        <option value="Swaziland">Swaziland</option>{" "}
                        <option value="Sweden">Sweden</option>{" "}
                        <option value="Switzerland">Switzerland</option>{" "}
                        <option value="Syrian Arab Republic">
                          {" "}
                          Syrian Arab Republic{" "}
                        </option>{" "}
                        <option value="Taiwan">Taiwan</option>{" "}
                        <option value="Tajikistan">Tajikistan</option>{" "}
                        <option value="Tanzania, United Republic of">
                          {" "}
                          Tanzania, United Republic of{" "}
                        </option>{" "}
                        <option value="Thailand">Thailand</option>{" "}
                        <option value="Timor-leste">Timor-leste</option>{" "}
                        <option value="Togo">Togo</option>{" "}
                        <option value="Tokelau">Tokelau</option>{" "}
                        <option value="Tonga">Tonga</option>{" "}
                        <option value="Trinidad and Tobago">
                          {" "}
                          Trinidad and Tobago{" "}
                        </option>{" "}
                        <option value="Tunisia">Tunisia</option>{" "}
                        <option value="Turkey">Turkey</option>{" "}
                        <option value="Turkmenistan">Turkmenistan</option>{" "}
                        <option value="Turks and Caicos Islands">
                          {" "}
                          Turks and Caicos Islands{" "}
                        </option>{" "}
                        <option value="Tuvalu">Tuvalu</option>{" "}
                        <option value="Uganda">Uganda</option>{" "}
                        <option value="Ukraine">Ukraine</option>{" "}
                        <option value="United Arab Emirates">
                          {" "}
                          United Arab Emirates{" "}
                        </option>{" "}
                        <option value="United Kingdom">
                          United Kingdom
                        </option>{" "}
                        <option value="United States">United States</option>{" "}
                        <option value="United States Minor Outlying Islands">
                          {" "}
                          United States Minor Outlying Islands{" "}
                        </option>{" "}
                        <option value="Uruguay">Uruguay</option>{" "}
                        <option value="Uzbekistan">Uzbekistan</option>{" "}
                        <option value="Vanuatu">Vanuatu</option>{" "}
                        <option value="Venezuela">Venezuela</option>{" "}
                        <option value="Viet Nam">Viet Nam</option>{" "}
                        <option value="Virgin Islands, British">
                          {" "}
                          Virgin Islands, British{" "}
                        </option>{" "}
                        <option value="Virgin Islands, U.S.">
                          {" "}
                          Virgin Islands, U.S.{" "}
                        </option>{" "}
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>{" "}
                        <option value="Western Sahara">Western Sahara</option>{" "}
                        <option value="Yemen">Yemen</option>{" "}
                        <option value="Zambia">Zambia</option>{" "}
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                  </div>


                  <br />
                  <br />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span>Duration To: </span>
                    </div>
                    <div className="col-12 col-md mb-2 mb-md-0">
                      <input
                        type="date"
                        className="form-control"
                        name="durationTo"
                        value={educationData?.durationTo}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-auto mb-2 mb-md-0 custom-width">
                      <span className="input-margin">
                        Subject/Specialization:
                      </span>
                    </div>
                    <div className="col-12 col-md">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="subjectSpecialization"
                        value={educationData?.subjectSpecialization}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <button
                    type="submit"
                    onClick={(e) => eductionHandleSubmit(e)}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </form>

                {/* <div className="container table-responsive">
                  <table className="table table-hover w-100 d-block d-md-table">
                    <thead>
                      <tr>
                        <th>Qualification</th>
                        <th>Degree Name</th>
                        <th>Duration From</th>
                        <th>Duration To</th>
                        <th>%age/GPA</th>
                        <th>Subject/Specialization</th>
                        <th>Board/University</th>
                      </tr>
                      <tr>
                        <td>Matric / OLevels / Equivalent</td>
                        <td>Computer Science</td>
                        <td>Mar-2021</td>
                        <td>Oct-2022</td>
                        <td>60</td>
                        <td>Biology</td>
                        <td>Peshawar Board</td>
                      </tr>
                    </thead>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
