import React from "react";
import "./ourServices.scss";

function Ourservices() {
  const menu = [
    {
      img: "./images/services1.png",
      heading: "One click application service ",
      title: "Let us help you will your applications",
      desc: "We are a customer service providing company aiming to help people apply for jobs as well as getting documentation process done by us.All you have to do, is to apply and receive the interview call. Leave the rest to AsaanApply",
    },
    {
      img: "./images/services3.png",
      heading: "Applications and documentations",
      title: "Easily apply for Jobs at out platform",
      desc: "We help students and graduates who are looking to apply for internships and jobs but don’t want to waste time doing the physical hustle of repeatedly filling forms,submitting bank chalans, sending documents through couriers all the time when job or internship opportunities are advertised. We help them with these tasks and let them just apply through one click platform and focus on their preparation for test and interviews.",
    },
    {
      img: "./images/Asan Apply 2-04.png",
      heading: "Standout in the crowd",
      title: "Portfolio building ",
      desc: "We also help you in resume building, portfolio building,training courses and professional certificates that will help you in your career path towards a good job.",
    },


  ];

  const isOdd = (number) => number % 2 !== 0;

  return (
    <section className="ourmission  ">
      <div className="container ">
        <div className="rowz">
          <div className="sec-main">
            <div className=" mb-4 mt-5 pt-5 sec-header">
              <h1 className="fw-bold">
                Here Are Our
                <span className="coloured"> SERVICES </span>
                For Your Need What you get with
                <span className="coloured"> AsaanApply </span>
              </h1>
            </div>
            <div className="header-txt ">
              <p className="">
                <h5>
                  How can you make the most of this integral part of your
                  marketing strategy?<br></br>In this article, you’ll learn what
                  makes an exceptional About our page
                </h5>
              </p>
            </div>
          </div>
          {/* section start */}

          {menu.map((item, index) => {
            if (isOdd(index + 1)) {
              return (
                <>
                  <div className="main">
                    <div className="row d-flex flex-row  var-row justify-content-center align-items-center">
                      <div className="custom-style col-lg-6 col-md-12 col-sm-12">
                        <p className="variable service-heading">{item.heading}</p>
                        <h4 className="fw-bold service-title">{item.title}</h4>
                        <p className="para par1 "> {item.desc} </p>
                      </div>
                      <div className="custom-style col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                        <img
                          className="img "
                          src={item.img}
                          alt="my-image2"
                        ></img>
                      </div>
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="main">
                    <div className="row d-flex flex-row var-row justify-content-center align-items-center  ">
                      <div className="custom-style col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center  ">
                        <img
                          className="img"
                          src={item.img}
                          alt="my-image2"
                        ></img>
                      </div>
                      <div className="custom-style col-lg-6 col-md-12 col-sm-12">
                        <p className="variable service-heading">{item.heading}</p>
                        <h4 className="fw-bold service-title">{item.title}</h4>
                        <p className="para par1"> {item.desc} </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
        {/* end sections */}
      </div>
    </section>
  );
}

export default Ourservices;
