// import { React, useState, useEffect } from "react";
// import "./auth.scss";
// import { Link } from "react-router-dom";
// import { IoEye } from "react-icons/io5";
// import { IoEyeOff } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
// import { clearMessage, signUpUser } from "../../Services/Reducers/UserSlice";
// import { useNavigate } from "react-router-dom";


// const SignUpPage = () => {
//   const [showPassword, setshowPassword] = useState(false);
//   const [data, setData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     confirm_password: "",
//   });
//   const navigate = useNavigate();

//   const passwordToggle = () => {
//     setshowPassword(!showPassword);
//   }

//   const dispatch = useDispatch();
//   const {successMessage } = useSelector((state)=> state.user);

//   const handleChange = (e) => {
//     setData({
//       ...data,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (data.password !== data.confirm_password) {
//       alert("Passwords do not match");
//       return;
//     }
//     dispatch(signUpUser(data));
//   };

//   useEffect(() => {
//     if(successMessage){
//       navigate('/login')
//       clearMessage()
//     }

//   }, [successMessage])
  
//     console.log("succes message >>>>", successMessage);

//   return (
//     <>
//       <section className="auth-section ">
//         <div className="row ">
//           {/* form head start*/}
//           <div className="col-md-4 col-sm-12 mx-auto ">
//             <div className="card  text-center  border">
//               <img src="./images/logo.jpg" className="img-fluid" alt=" logo" />
//               <h3 className="para_welcome mb-4 mt-3">Welcome back!</h3>
//               <p className="text-muted mb-2" style={{ fontSize: "12px" }}>
//                 Enter your Email and Password to log in
//               </p>
//               {/* form head end */}
//               {/* form main start*/}
//               <form className="log-register mt-3" onSubmit={handleSubmit} >

//               <div className="f-group text-start">
//                   <label className="f-label mb-2">Name*</label>
//                   <input
//                     className="form-control mb-3"
//                     id="input-1"
//                     type="name"
//                     name="name"
//                     placeholder="Name"
//                     onChange={handleChange}
//                   ></input>
//                 </div>

//                 <div className="f-group text-start">
//                   <label className="f-label mb-2">Email*</label>
//                   <input
//                     className="form-control mb-3"
//                     id="input-1"
//                     type="email"
//                     name="email"
//                     placeholder="Example@***.com"
//                     onChange={handleChange}
//                   ></input>
//                 </div>
//                 <div className="f-group text-start mt-3">
//                   <label className="f-label mb-2">Password*</label>
//                   <div className="position-relative">
//                     <input className="form-control text-secondary pr-5"  // Add padding to the right to make space for the icon
//                       id="input-2"
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       placeholder="********"
//                       onChange={handleChange}
//                     />
//                     <div
//                       onClick={passwordToggle}
//                       className="position-absolute"
//                       style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
//                     >
//                       {showPassword ? <IoEyeOff /> : <IoEye />}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="f-group text-start mt-3">
//                   <label className="f-label mb-2"> Confirm Password*</label>
//                   <div className="position-relative">
//                     <input className="form-control text-secondary pr-5"  // Add padding to the right to make space for the icon
//                       id="input-2"
//                       type={showPassword ? "text" : "password"}
//                       name="confirm_password"
//                       placeholder="********"
//                       onChange={handleChange}
//                     />
//                     <div
//                       onClick={passwordToggle}
//                       className="position-absolute"
//                       style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
//                     >
//                       {showPassword ? <IoEyeOff /> : <IoEye />}
//                     </div>
//                   </div>
//                 </div>
//                 {/* form main end  */}

//                 <button
//                   className=" hover-up mb-1 mt-4 w-100"
//                   type="submit"
//                   name="login"
//                 >
//                   Register
//                 </button>

//                 <div className="pb-3 py-1 d-flex align-items-center mt-3">
//                   <p className="mb-0 text-muted">
//                     {" "}
//                     <small>Already have an account?</small>{" "}
//                   </p>
//                   <Link className="ms-1" to={"/login"}>
//                     <small>Sign In</small>
//                   </Link>
//                 </div>
//               </form>
//             </div>
//           </div>
//           {/* sign in buttons end  */}
//         </div>
//       </section>
//     </>
//   );
// };

// export default SignUpPage;



import React, { useState, useEffect } from "react";
import "./auth.scss";
import { Link, useNavigate } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, signUpUser } from "../../Services/Reducers/UserSlice";

const SignUpPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [alerts, setAlerts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, error } = useSelector((state) => state.user);

  const passwordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password !== data.confirm_password) {
      setAlerts([
        { type: 'danger', message: 'Passwords do not match' },
      ]);
      return;
    }
    dispatch(signUpUser(data));
  };

  useEffect(() => {
    let intervalId;

    if (successMessage) {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        { type: 'success', message: successMessage },
      ]);
      setTimeout(() => {
        navigate('/login');
        dispatch(clearMessage());
      }, 2000);
    }

    if (error) {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        { type: 'danger', message: error },
      ]);
      dispatch(clearMessage());
    }

    if (alerts.length > 0) {
      intervalId = setInterval(() => {
        setAlerts((prevAlerts) => {
          if (prevAlerts.length > 0) {
            return prevAlerts.slice(1);
          }
          return prevAlerts;
        });
      }, 3000);
    }

    return () => clearInterval(intervalId);

  }, [successMessage, error, navigate, dispatch, alerts]);

  return (
    <>
      <section className="auth-section">
        <div className="row">
          <div className="col-md-4 col-sm-12 mx-auto">
            <div className="card text-center border">
              <img src="./images/logo.jpg" className="img-fluid" alt="logo" />
              <h3 className="para_welcome mb-4 mt-3">Welcome to Registration!</h3>
              <p className="text-muted mb-2" style={{ fontSize: "12px" }}></p>

              {/* Alert */}
              {alerts.map((alert, index) => (
                <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                  {alert.message}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              ))}

              {/* form main start */}
              <form className="log-register-form mt-3" onSubmit={handleSubmit}>
                <div className="f-group text-start">
                  <label className="f-label mb-2">Name*</label>
                  <input
                    className="form-control mb-3"
                    id="input-1"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={data.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="f-group text-start">
                  <label className="f-label mb-2">Email*</label>
                  <input
                    className="form-control mb-3"
                    id="input-1"
                    type="email"
                    name="email"
                    placeholder="Example@***.com"
                    value={data.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="f-group text-start mt-3">
                  <label className="f-label mb-2">Password*</label>
                  <div className="position-relative">
                    <input
                      className="form-control text-secondary pr-5"
                      id="input-2"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="********"
                      value={data.password}
                      onChange={handleChange}
                      required
                    />
                    <div
                      onClick={passwordToggle}
                      className="position-absolute"
                      style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    >
                      {showPassword ? <IoEyeOff /> : <IoEye />}
                    </div>
                  </div>
                </div>
                <div className="f-group text-start mt-3">
                  <label className="f-label mb-2">Confirm Password*</label>
                  <div className="position-relative">
                    <input
                      className="form-control text-secondary pr-5"
                      id="input-2"
                      type={showPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="********"
                      value={data.confirm_password}
                      onChange={handleChange}
                      required
                    />
                    <div
                      onClick={passwordToggle}
                      className="position-absolute"
                      style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    >
                      {showPassword ? <IoEyeOff /> : <IoEye />}
                    </div>
                  </div>
                </div>
                <button
                  className="hover-up mb-1 mt-4 w-100"
                  type="submit"
                >
                  Register
                </button>
                <div className="pb-3 py-1 d-flex align-items-center mt-3">
                  <p className="mb-0 text-muted">
                    <small>Already have an account?</small>
                  </p>
                  <Link className="ms-1" to="/login">
                    <small>Sign In</small>
                  </Link>
                </div>
              </form>
              {/* form main end */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpPage;
