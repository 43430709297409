import './_contact.scss';
import { } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Link } from 'react-router-dom';

export default function Contact() {
    return (
        <>
            <section className="container second-contain for-space">
                <div className="row p-3">
                    <div className="col-lg-5   col-md-12 col-sm-12 we-contain mt-1 mb-1">
                        <h3 className="contact-detail mb-3">Contact Detail</h3>
                        <h4 className="we-head">We'd love to hear from you</h4>
                        <p className='fs-6'>Nostra porta aliquet nisl malesuada lacus. Inceptos sociosqu amet aenean dui metus ultrices
                            maecenas ut tellus tempus.</p>
                        <div className="row mt-4 ">
                            <div className="col-1 icon-margin">
                                <span className="icon-contact">
                                    <i className="fas fa-paper-plane"></i>
                                </span>
                            </div>
                            <div className="col-10">
                                <h4 className="contact-heading-3">Address</h4>
                                <p className='fs-6'>Jln Cempaka wangi No 22, Jakarta - Indonesia</p>
                            </div>
                        </div>
                        <hr style={{ width: '90%', margin: '0' }} />
                        <div className="row mt-4">
                            <div className="col-1 icon-margin">
                                <span className="icon-contact">
                                    <i className="fa fa-at" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="col-10">
                                <h3 className="contact-heading-3">Mail Address </h3>
                                <a  href='mailto:hello@yourdomain.tld' className='fs-6 mail-link'  >hello@yourdomain.tld </a><br />
                                <a href='mailto:support@yourdomain.tld' className='fs-6 mail-link'  >support@yourdomain.tld </a>
                            </div>
                        </div>
                        <hr style={{ width: '90%', margin: '0' }} />
                        <div className="row mt-4">
                            <div className="col-1 icon-margin">
                                <span className="icon-contact">
                                    <i className="fa-solid fa-phone-volume"></i>
                                </span>
                            </div>
                            <div className="col-10">
                                <h3 className="contact-heading-3">Phone</h3>
                                <a className='fs-6' href='tel:+6221.2002.2012'>+6221.2002.2012</a><br />
                                <a className='fs-6' href='+6221.2002.2021'>+6221.2002.2021</a>
                                
                            </div>
                        </div>
                        <hr style={{ width: '90%', margin: '0' }} />


                    </div>
                    <div className="col-lg-5  col-md-12 col-sm-12  mx-lg-5 form-contain mt-1 mb-1 ">
                        <h3 className="we-head ps-3">Send us a Message</h3>
                        <p className="fs-6 ps-3">Enim suspendisse nisi egestas nulla diam ornare dignissim.</p>
                        <form id="contact-form" >
                            <div className="controls">
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_name">Name </label>
                                            <input id="form_name" type="text" name="name" className="form-control input-contact"
                                                placeholder="wajid ..." />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_lastname">Phone </label>
                                            <input id="form_Phone" type="text" name="surname"
                                                className="form-control input-contact" placeholder="0000-0000000" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_email">Email </label>
                                            <input id="form_email" type="email" name="email"
                                                className="form-control input-contact" placeholder="something@gmail.com " />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_email">Company </label>
                                            <input id="form_Company" type="Company" name="Company"
                                                className="form-control input-contact" placeholder=" google , microsoft ... " />

                                        </div>
                                    </div>

                                </div>
                                <div className=" row mb-3">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_message">Subject</label>
                                            <input id="form_message" name="message" className="form-control input-contact"
                                                placeholder="Want to know about application status ...." />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 ">
                                        <div className="form-group">
                                            <label className="label-contact" for="form_message">Message </label>
                                            <textarea id="form_message" name="message" className="form-control input-contact"
                                                placeholder="Write your message here." rows="4"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <input type="submit" className="btn btn-primary btn-send btn-get-started pt-2 btn-block
                                            " value="Send Message" />
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section >
        </>
    )
}

