import React from "react";
import "./AboutUs.scss";
import Typed from 'typed.js';

function AboutUs() {
    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['<i>Hello</i> there!', '&amp; Welcome to <i>Asaan Apply</i>.'],
            typeSpeed: 50,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);
    return (
        <>
            <section className="">
                <div className="about-us-section">
                    <div className="container pt-5 pb-5">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="card-about">
                                <h2 className="text-center fw-bold fs-1"> <span ref={el} /> </h2>

                                <div>
                                    <p className="mt-4">
                                        We are team of young and energetic professionals who have the aim of bringing innovation and
                                        easiness to the job application process for individuals who want to get employed but have no
                                        or limited time for job applications every time they are advertised. We understand the value of
                                        your time and aim to minimize the physical efforts required in your job application process. We
                                        believe in a process designed to save you time and eliminate the hassle of dealing with
                                        paperwork will allow you to focus on what matters most – preparing for your future.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-md-6">
                            <h2 className="fs-1 fw-bold w-50 border-bottom">About us</h2>
                            <p className="mt-3">At AsaanApply, we understand that the journey of finding internships, jobs, or making a career
                                change can often be daunting and time-consuming. That's why we are here to simplify the
                                process and take care of the physical hassles for you. Our mission is to provide comprehensive
                                assistance to students, graduates, and employed individuals, ensuring a smooth and stressfree job application experience.</p>
                        </div>
                        <div className="col-md-5">
                            <img src="/images/about.avif" alt="aboutimage" className="img-fluid" />
                        </div>

                    </div>
                </div>
                <hr />
                <div className="container mt-5">
                    <div className="row d-flex justify-content-between">
                        <h2 className="fs-1 fw-bold text-center">WHY US?</h2>
                        <div className="col-md-5 mt-5 ">
                            <div className="border-right-about">
                                <h5 className="mx-3">01</h5>
                                <h2 className="mx-3">Convenience</h2>
                            </div>
                        </div>
                        <div className="col-md-5 mt-5">
                            <p>
                                We understand the value of your time and aim to minimize the physical efforts required in your
                                job application process. Our services are designed to save you time and eliminate the hassle of
                                dealing
                            </p>
                        </div>
                        <div className="col-md-5 mt-5">
                            <div className="border-right-about">
                                <h5 className="mx-3">02</h5>
                                <h2 className="mx-3">Expertise</h2>
                            </div>
                        </div>
                        <div className="col-md-5 mt-5">
                            <p>Our team consists of professionals who have extensive experience in the field of career
                                services. We are well-versed in the intricacies of job applications and understand what it takes
                                to stand out from the competition. Rest assured, you will receive expert guidance and support
                                at every step.</p>
                        </div>
                        <div className="col-md-5 mt-5">
                            <div className="border-right-about">
                                <h5 className="mx-3">03</h5>
                                <h2 className="mx-3">Customized Approach</h2>
                            </div>
                        </div>
                        <div className="col-md-5 mt-5">
                            <p>
                                We recognize that each individual's needs are unique. That's why we take a personalized
                                approach to ensure that our services are tailored to meet your specific requirements. Whether
                                you are a student, a graduate, or an employed individual seeking a career change, we will
                                provide the assistance you need to achieve your goals
                            </p>
                        </div>
                        <div className="col-md-5 mt-5">
                            <div className="border-right-about">
                                <h5 className="mx-3">04</h5>
                                <h2 className="mx-3">Confidentiality</h2>
                            </div>
                        </div>
                        <div className="col-md-5 mt-5">
                            <p>
                                Your privacy and confidentiality are of utmost importance to us. We handle all your documents
                                and personal information with the utmost care and ensure that they are protected at all times.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row for-feedback d-flex justify-content-center mt-5">
                    <div className="col-sm-10 col-md-8 col-lg-8">
                         <p className=" text-center feedback-text">
                            Let us handle the physical hassles of your job application process, so you can focus on your
                            career goals. Contact us today and experience a smoother, more efficient path to success
                         </p>
                    </div>
                </div>

            </section>
        </>
    )
}
export default AboutUs;