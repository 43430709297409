// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import AxiosInstance from '../../components/utils/AxiosInstance ';

// export const signUpUser = createAsyncThunk(
//   'user/signUp',
//   async (userData, { rejectWithValue }) => {
//     try {
//       const response = await AxiosInstance.post('/api/user/signup', userData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );


// export const loginUser = createAsyncThunk(
//   'user/login',
//   async (userData, { rejectWithValue }) => {
//     try {
//       const response = await AxiosInstance.post('/api/user/login', userData);
//       localStorage.setItem('token', response.data.token);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const userInformation = createAsyncThunk(
//   'user/userInformation', async (userData , {rejectWithValue}) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await AxiosInstance.post('/api/user/dashboard', userData, {
//         headers: {
//           'Authorization' : `Bearer ${token}`
//         }
//       });
        
//       return response.data;
//     } catch (error) {
//         return rejectWithValue(error.response.data);
//     }
//   }
// );
// export const educationInformation = createAsyncThunk(
//   'user/educationInformation', async (userData , {rejectWithValue}) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await AxiosInstance.post('/api/user/dashboard/education', userData, {
//         headers: {
//           'Authorization' : `Bearer ${token}`
//         }
//       });
        
//       return response.data;
//     } catch (error) {
//         return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const getUserData = createAsyncThunk(
//   'user/getUserData', async (_, {rejectWithValue}) =>{
//     try {
//         const token = localStorage.getItem('token');
//         const response = await AxiosInstance.get('/api/user/dashboard/user', {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });
//         return response.data;
//     } catch (error) {
//         return rejectWithValue(error.response.message);
//     }
//   }
// )

// export const fetchEducationData = createAsyncThunk(
//   'education/fetchEducationData',
//   async (_, { rejectWithValue }) => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await AxiosInstance.get('/api/user/dashboard/educationdata', {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );


// const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     loading: false,
//     error: null,
//     successMessage: null,
//     token: localStorage.getItem('token') || null,
//     userData: null,
//     educationData: null,
//     step:null
//   },
//   reducers: {

//     clearMessage(state) {
//       state.successMessage = null
//         state.userData = null
//         state.educationData=null
//     }
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUpUser.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//         state.successMessage = null;
//       })
//       .addCase(signUpUser.fulfilled, (state, action) => {
//         state.loading = false;
//         state.successMessage = action.payload.message;
//       })
//       .addCase(signUpUser.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload.message;
//       })
//       .addCase(loginUser.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//         state.successMessage = null;
//       })

//       .addCase(loginUser.fulfilled, (state, action) => {
//         state.loading = false;
//         state.successMessage = action.payload.message
//         state.step=action.payload.step
//       })

//       .addCase(loginUser.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload.message;
//         state.step=action.payload.step
//       })
//       .addCase(userInformation.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })

//       .addCase(userInformation.fulfilled, (state, action) => {
//         state.loading = false;
//         state.successMessage = action.payload.message;
//         state.step=action.payload.step
//       })

//       .addCase(userInformation.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload.message;
//       })
//       .addCase(educationInformation.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })

//       .addCase(educationInformation.fulfilled, (state, action) => {
//         state.loading = false;
//         state.successMessage = action.payload.message;
//         state.step=action.payload.step
//       })

//       .addCase(educationInformation.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload.message;
//       })
//       .addCase(getUserData.pending, (state)=>{
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(getUserData.fulfilled, (state, action)=>{
//         state.loading = false;
//         state.userData = action.payload;
//         state.step=action.payload.step;
//       })
//       .addCase(getUserData.rejected, (state, action)=>{
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(fetchEducationData.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchEducationData.fulfilled, (state, action) => {
//         state.loading = false;
//         state.educationData = action.payload;
//         state.step=action.payload.step;
//       })
//       .addCase(fetchEducationData.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });
// export const {clearMessage}=userSlice.actions
// export default userSlice.reducer;



////////////////////////////////////////////
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../components/utils/AxiosInstance ';


// Async Thunks
export const signUpUser = createAsyncThunk(
  'user/signUp',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post('/api/user/signup', userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/login',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post('/api/user/login', userData);
      localStorage.setItem('token', response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userInformation = createAsyncThunk(
  'user/userInformation',
  async (userData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await AxiosInstance.post('/api/user/dashboard', userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const educationInformation = createAsyncThunk(
  'user/educationInformation',
  async (userData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await AxiosInstance.post('/api/user/dashboard/education', userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await AxiosInstance.get('/api/user/dashboard/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

export const fetchEducationData = createAsyncThunk(
  'education/fetchEducationData',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await AxiosInstance.get('/api/user/dashboard/educationdata', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: null,
    successMessage: null,
    token: localStorage.getItem('token') || null,
    userData: null,
    educationData: null,
    step: null,
    loginError: null, // Add this line to track login errors
  },
  reducers: {
    clearMessage(state) {
      state.successMessage = null;
      state.error = null;
      state.loginError = null; // Clear login error message
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
        state.loginError = null; // Clear previous login errors
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
        state.step = action.payload.step;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.loginError = action.payload.message; // Set login error message
      })
      .addCase(userInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
        state.step = action.payload.step;
      })
      .addCase(userInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(educationInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(educationInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
        state.step = action.payload.step;
      })
      .addCase(educationInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.step = action.payload.step;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEducationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEducationData.fulfilled, (state, action) => {
        state.loading = false;
        state.educationData = action.payload;
        state.step = action.payload.step;
      })
      .addCase(fetchEducationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearMessage } = userSlice.actions;
export default userSlice.reducer;
