import React from "react";
import "./ourmission.scss";

function Ourmission() {
  const menu = [
    {
      heading: "EASE",
      title: "Digitizing the job searching",
      img: "./images/services6.png",
      desc: "The job application is a tedious process and often times very confusing. It is very difficult to find a right path towards one’s career interests. Our mission is to digitalize the job application process and innovate the job searching in an easy few step process specially for individuals with busy schedule due to schooling or work loads so that they can apply for jobs with ease and reliability.",
    },
    {
      heading: "GUIDANCE",
      title: "Expert team for your guidance",
      img: "./images/services4.png ",
      desc: "Expert guidance in fact plays a critical role in understanding career goals and achieving them. At AsaanApply we believe expert led career counseling better help in finding good career opportunities for individuals no matter they are in university looking for internships, choosing majors or subjects, graduates in their search for right job opportunity.",
    },
    {
      heading: "INFORM",
      title: "Ease of information access",
      img: "./images/services5.png",
      desc: "With fast growing information communication age, its often gets confusing for graduates and working professionals to make career choices as relevant information is all spread around different mediums. Our mission is to create an online platform where the application process, career help and job application management is easily available to students, unemployed and working professionals, so that they make better career choices.",
    },
  ];

  const isOdd = (number) => number % 2 !== 0;

  return (
    <section className="who_we_are">
      <div className="container">
        <div className="col-md-10 text-center mx-auto mb-1">
          <h1 className="">
              Our Mission
          </h1>
        </div>

        <div className="col-md-12 mx-auto who_card_box">
          {menu.map((item, index) => {
            if (isOdd(index + 1)) {
              return (
                <div className="row align-items-center" key={index}>
                  <div className="col-md text-start">
                    <img className="i" src={item.img} alt={item.title} />
                  </div>
                  <div className="col-md-12 col-lg-7">
                    <span className="d-inline-block text-primary mb-3">
                      {item.heading}
                    </span>
                    <h4 className="mb-2"> {item.title} </h4>
                    <p> {item.desc} </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="row align-items-center" key={index}>
                  <div className="col-md-12 col-lg-7">
                    <span className="d-inline-block text-primary mb-3">
                      {item.heading}
                    </span>
                    <h4 className="mb-2"> {item.title} </h4>
                    <p> {item.desc} </p>
                  </div>
                  <div className="col-md text-end">
                    <img className="i" src={item.img} alt={item.title} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default Ourmission;
