import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useState } from "react";
import "./home.scss";

import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import Ourmission from "../../components/ourMission/ourmission";
import Testimonials from "../../components/Testimonials/Testimonials";
import Pricingplan from "../../components/Pricingplan/index";

const index = () => {
  const data = [
    "One click process",
    "No need to waste time doing tiring lengthy processes",
    "No need to paying bank chaplains",
    "No need for photocopying and attestations",
    "No need for making pictures every time and wasting money",
    "Relief and reliability",
    "Let us take care of all above and you focus on getting hired",
  ];
  return (
    <>
      {/* Home section */}
      <section className="hero-section">
        <div className="content container">
          <div className="col-10 mx-auto">
            <div className="row allignment">
              <div className="col-lg-5 col-sm-12 col-md-12 custom-size">
                <h1 className="title mb-3">Make job application easy!!!</h1>
                <h5 className="mb-4 sub-heading-home">
                  Let us take care of it while you focus on preparation
                </h5>
                {data.map((list, ind) => (
                  <p className="d-flex align-items-center home__para" key={ind}>
                    <span className="me-3">
                      <FontAwesomeIcon
                        style={{ fill: "#0d8065" }}
                        icon={faCheck}
                      />
                    </span>
                    {list}
                  </p>
                ))}
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 main-card-conatiner text-center d-flex flex-column justify-content-center align-items-center">
                <img
                  src="./images/creater-profile.png"
                  width={"80px"}
                  height={"80px"}
                  alt="card-img"
                  className="img-fluid img-profile"
                />
                <div className="card">
                  <h4 className="mb-2 para_color mt-4">
                    {" "}
                    Let's see how we can help{" "}
                  </h4>
                  <p className="mb-4 text-center query-text" style={{width: "235px"}}>
                    {" "}
                    What will be a convenient medium for you to help with your
                    querry?{" "}
                  </p>

                  <div className="d-flex justify-content-center align-items-center mb-3 w-100">
                    <select
                      className="form-select w-100 m-0"
                      aria-label="Default select example"
                    >
                      <option defaultValue="">Choose your reason</option>
                      <option value={1}>one</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-primary d-flex align-items-center justify-content-center" style={{width:"100%"}} >
                    NEXT
                    <FontAwesomeIcon className=" ms-3" icon={faArrowRight} />
                  </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <video
          autoPlay
          src="./videos/home-video.mp4"
          className="home-video mt-2"
        ></video>
        </div>
      </section>

      {/* bar section in home page */}
      <div className="panel-svg">
        <div className="container">
          <ul className="mb-0 d-flex flex-lg-row flex-column justify-content-center align-items-center  ">
            <div className="col-md-6 col-sm-12 d-flex flex-row justify-content-between align-items-center">
            <li>
              <img src="/images/icons/apartment.svg" alt="industry" />
              <h5 className="fw-normal text-center mt-2">20+ Industries</h5>
            </li>

            <li>
              <img src="/images/icons/map.svg" alt="province" />
              <h5 className="fw-normal text-center mt-2">All provinces </h5>
            </li>
            </div>
              
            <div className="col-md-6 col-sm-12 d-flex flex-row justify-content-between align-items-center">
            <li>
              <img src="/images/icons/teamwork.svg" alt="employees" />
              <h5 className="fw-normal text-center mt-2">50+ employees</h5>
            </li>

            <li>
              <img src="/images/icons/diploma2.svg" alt="certified" />
              <h5 className="fw-normal text-center mt-2">
                ISO 27001 Certified
              </h5>
            </li>
            </div>
          </ul>
        </div>
      </div>

      {/* how does Asaanapply work */}
      <section className="how_assanapply_work py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto text-center mb-5">
              <h1 className="howdoes mb-3">
                How Does <span className="text-primary">AsaanApply</span> Work?
              </h1>
              <p className="fw-normal">
                With the help of our dedicated team for your job application
                process, AsaanApply helps you in job search according to your
                educational background and priorities, oversee application
                process including application form filling, attaching relevant
                documents and pictures if needed, submitting bank chalans if
                needed, sending the application package through a trustworthy
                and responsible courier service provider, collecting relevant
                preparation materials, notifying you on your preparation status,
                reminding you about test and interview dates and times,
                follow-up on the specific application, and update you regularly.
                In summary, AsaanApply will do all the physical work so you can
                focus on your test and interview preparations and getting hired.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-lg-6 mx-auto pt-4">
              <iframe
                className="w-100"
                height="320"
                src="https://www.youtube.com/embed/rzJhkTm7baY"
                title="Bambee | Hire an HR Manager for $99/month"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* Do you know */}
      <section className="do_you_know py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 d-flex align-items-center">
              <img
                src="./images/icons/do_you_know.svg"
                className="w-auto me-3"
                alt="icon"
              />
              <div className="flex-grow-1 d-flex flex-column">
                <h6 className="mb-2">DID YOU KNOW ?</h6>
                <p className="mb-0">
                  AsaanApply clients are 4x more likely to get hired
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Who we are */}
      <WhoWeAre />

      {/* Assan Apply mission */}
      <Ourmission />

      {/* Pricing plans */}
      <Pricingplan />

      {/* Do you know */}
      <section className="do_you_know py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 d-flex align-items-center">
              <img
                src="./images/icons/do_you_know.svg"
                className="w-auto me-3"
                alt="icon"
              />
              <div className="flex-grow-1 d-flex flex-column">
                <h6 className="mb-2">DID YOU KNOW ?</h6>
                <p className="mb-0">
                AsaanApply will soon launch major selection,<br/> scholarship guidance and career counseling 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial */}
      <Testimonials />
    </>
  );
};

export default index;
