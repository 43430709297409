import React from "react";
import "./Career.scss";

function Career() {
  let career = [
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },
    {
      "job": "Customer Operations",
      "title": "Customer Success Manager (CSM)",
      "location": "Peshawar",

    },


  ]
  const showCareer = (career) => {
    return (

      <div className="col-12 ">
        <h3 className="job4">{career.job}</h3>
        <h3 className="job5"><a className="text-decoration-none" href="">{career.title}</a></h3>
        <p className="job6 text-muted">{career.location}</p>
      </div>


    );
  }
  return (
    <>
      <section className="section-box mt-5 py-5 bg-light">
        <div className="container jobcontainer border mt-4  pt-3 bg-white">
          <h1 className="job1 mt-2 mb-3"><span>AsaanApply</span></h1>
          <h4 className="job2 mb-3 mt-3">If AsaanApply's values resonate with you, we'd love to hear from you. Apply now to join our fast-growing team of rockstars and start the most fulfilling work of your career.</h4>
          <h2 className="job3">Current Job Openings</h2>
          <div className="row mt-3">
            {career.map(showCareer)}
          </div>
        </div>
      </section>
    </>
  )
}
export default Career;